import React, { useState, useEffect, useRef } from "react";
import { notification, Spin, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import LeftSideBar from "../LeftSideBar";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import PhoneInput, {
    isValidPhoneNumber,
    formatPhoneNumber,
} from "react-phone-number-input";
import {
    EditSvgIcon,
    SaveSvgIcon,
    TickSvgIcon,
} from "../../assets/Svg/SvgIcons";
import { nextFlow } from "../../redux/student/studentSlice";
import useRequestOTP from "../../api/useRequestOtp";
import useRequestOTPEmail from "../../api/RequestOtpEmail";
import useVerifyOTP from "../../api/useVerifyOtp";
import useVerifyOTPEmail from "../../api/VerifyOtpEmail";
import useUpdateVerifyStudents from "../../api/useUpdateVerifyStudent";
import useUpdateUserInfo from "../../api/UpdateUserInfo";
import "react-phone-number-input/style.css";
import "./styles.css";

const ReSubmissionScreen = () => {
    const { client_name, test_type } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { test_series_id } = location.state || {};
    const currentQueryParams = location.search;

    const {
        emailData: requestedDataOTPEmail,
        isRequestingOTP: requestiongOTPEmail,
        errorOTP: requestingOTPEmailError,
        requestOTP,
    } = useRequestOTPEmail();

    const {
        phoneNumberData: requestedDataOTPPhone,
        isRequestingOTP: requestiongOTPPhone,
        errorOTP: requestingOTPPhoneError,
        requestOTP: RequestOTPPhone,
    } = useRequestOTP();

    const {
        data: emailVerifyData,
        isLoading: emailVerifying,
        error: emailVerifyError,
        verifyOTP: VerifyOTPEmail,
    } = useVerifyOTPEmail();

    const {
        data: phoneVerifiesData,
        isLoading: phoneVerifying,
        error: phoneVerifyError,
        verifyOTP: VerifyOTPPhone,
    } = useVerifyOTP();

    const {
        response: updatedResponse,
        isLoading: updatingStudent,
        error: updatingStudentError,
        updateVerifyStudents,
    } = useUpdateVerifyStudents();

    const {
        response: updatedUserInfo,
        error: updatedUserInfoError,
        isLoading: userInfoUpdating,
        UpdateUserInfo,
    } = useUpdateUserInfo();

    const clientConfig = useSelector( ( state ) => state.clientConfiguration );
    const inputRefs = useRef( [] );

    const [name, setName] = useState( "Pravalika Chinthala" );
    const [email, setEmail] = useState( "kynulavi@polkaroad.net" );
    const [phone, setPhone] = useState( "+91 123 456 7898" );

    useEffect( () => {
        const localStorageName = localStorage.getItem( 'studentName' )
        const localStorageEmail = localStorage.getItem( 'user_email' )
        const localStoragePhoneNumber = localStorage.getItem( 'phone_number' )
        setName( localStorageName );
        setEmail( localStorageEmail );
        setPhone( localStoragePhoneNumber );
    }, [] )

    const [isEditingName, setIsEditingName] = useState( false );
    const [isEditingEmail, setIsEditingEmail] = useState( false );
    const [isEditingPhone, setIsEditingPhone] = useState( false );
    const [otpVisible, setOtpVisible] = useState( false );
    const [otp, setOtp] = useState( ["", "", "", ""] );
    const [timer, setTimer] = useState( 30 );
    const [resendClickable, setResendClickable] = useState( false );
    const [buttonText, setButtonText] = useState( "" );

    const currentFlowName =
        sessionStorage.getItem( "currentFlowName" ) || "email_verification";

    const clientId = clientConfig.client_id || localStorage.getItem( "clientId" );
    const studentRollNumber = localStorage.getItem( "student_roll_number" );


    useEffect( () => {
        if ( currentFlowName === "email_verification" ) {
            setButtonText( "Send OTP" );
        } else if ( currentFlowName === "phone_verification" ) {
            setButtonText( "Send OTP" );
        }
    }, [currentFlowName] );

    const handleSentOtp = () => {
        if ( currentFlowName === "email_verification" ) {
            requestOTP( email, client_name );
        } else if ( currentFlowName === "phone_verification" ) {
            RequestOTPPhone( phone, clientId );
        }
        setTimer( 30 );
        setResendClickable( false );
    };

    useEffect( () => {
        if ( requestedDataOTPEmail && requestedDataOTPEmail?.msg ) {
            notification.success( {
                message: requestedDataOTPEmail?.msg,
            } );
            setOtpVisible( true );
            setButtonText( "Verify OTP with Above Details" );
        } else if ( requestedDataOTPPhone && requestedDataOTPPhone?.success ) {
            notification.success( {
                message: 'OTP sent successfully',
            } );
            setOtpVisible( true );
            setButtonText( "Verify OTP with Above Details" );
        }
    }, [requestedDataOTPEmail, requestedDataOTPPhone] );

    const handleOtpChange = ( e, index ) => {
        const { value } = e.target;
        const newOtp = [...otp];

        if ( /^[0-9]$/.test( value ) ) {
            newOtp[index] = value;
            setOtp( newOtp );
            if ( index < otp.length - 1 ) {
                inputRefs.current[index + 1].focus();
            }
        } else if (
            value === "" &&
            e.nativeEvent.inputType === "deleteContentBackward"
        ) {
            if ( index > 0 ) {
                inputRefs.current[index - 1].focus();
            }
            newOtp[index] = "";
            setOtp( newOtp );
        }
    };

    useEffect( () => {
        if ( timer > 0 && otpVisible ) {
            const countdown = setTimeout( () => {
                setTimer( timer - 1 );
            }, 1000 );
            return () => clearTimeout( countdown );
        } else {
            setResendClickable( true );
        }
    }, [timer, otpVisible] );

    const handleVerifyOTP = () => {
        const otpValue = otp.join( "" );

        if ( currentFlowName === "email_verification" ) {
            VerifyOTPEmail( email, otpValue );
        } else if ( currentFlowName === "phone_verification" ) {
            VerifyOTPPhone( phone, otpValue );
        }
    };

    useEffect( () => {
        if ( emailVerifyData && emailVerifyData?.success ) {
            updateVerifyStudents( studentRollNumber, true, false );
        } else if ( phoneVerifiesData && phoneVerifiesData?.success ) {
            updateVerifyStudents( studentRollNumber, false, true );
        }
    }, [emailVerifyData, phoneVerifiesData] );

    useEffect( () => {
        if ( updatedResponse && updatedResponse?.message ) {
            UpdateUserInfo( { studentRollNumber: studentRollNumber, userName: name, userEmail: email, userPhoneNumber: phone } )
        }
    }, [updatedResponse] );

    useEffect( () => {
        if ( updatedUserInfo ) {
            notification.success( {
                message: "OTP Verified",
                description: "Your OTP has been successfully verified.",
            } );
            if ( test_series_id ) {
                navigate(
                    `/${ client_name }/${ test_type }/overall_instructions/?${ currentQueryParams }`,
                    { replace: true, state: { test_series_id: test_series_id } }
                );
            } else {
                dispatch( nextFlow() );
                navigate( `/${ client_name }/${ test_type }/flow/?${ currentQueryParams }`, {
                    replace: true,
                } );
            }
        }
    }, [updatedUserInfo] )

    useEffect( () => {
        if ( emailVerifyError && emailVerifyError?.response?.status ) {
            notification.error( {
                message: 'OTP is invalid'
            } )
        } else if ( phoneVerifyError && phoneVerifyError?.response?.status ) {
            notification.error( {
                message: 'OTP is invalid'
            } )
        }
    }, [emailVerifyError, phoneVerifyError] )

    return (
        <div>
            <div className="flex flex-col md:flex-row min-h-screen">
                <LeftSideBar />
                <div className="flex flex-col items-center justify-center px-3 py-8 w-full font-poppins">
                    <div className="sm:w-full lg:w-2/3 bg-white shadow-custom rounded-lg p-6 border border-1 border-black">
                        <div className="flex items-center mb-2">
                            <TickSvgIcon />
                            <h2 className="text-xl font-semibold ml-3 mb-0">
                                Confirm your details
                            </h2>
                        </div>
                        <div className="border-t border-gray-200 pt-4">
                            <div className="flex items-center justify-between mb-4">
                                <label className="text-gray-600 w-1/2">Name</label>
                                <div className="flex items-center w-1/2 justify-end">
                                    <input
                                        type="text"
                                        value={ name }
                                        onChange={ ( e ) => setName( e.target.value ) }
                                        className="border rounded p-2 w-full"
                                        disabled={ !isEditingName }
                                    />
                                    <button
                                        className="ml-2 text-blue-500"
                                        onClick={ () => setIsEditingName( !isEditingName ) }
                                    >
                                        { isEditingName ? <EditSvgIcon /> : <SaveSvgIcon /> }
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-between mb-4">
                                <label className="text-gray-600 w-1/2">Email ID</label>
                                <div className="flex items-center w-1/2 justify-end">
                                    <input
                                        type="text"
                                        value={ email }
                                        onChange={ ( e ) => setEmail( e.target.value ) }
                                        className="border rounded p-2 w-full"
                                        disabled={ !isEditingEmail }
                                    />
                                    <button
                                        className="ml-2 text-blue-500"
                                        onClick={ () => setIsEditingEmail( !isEditingEmail ) }
                                    >
                                        { isEditingEmail ? <EditSvgIcon /> : <SaveSvgIcon /> }
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-between mb-6">
                                <label className="text-gray-600 w-1/2">Phone Number</label>
                                <div className="flex items-center w-1/2 justify-end">
                                    <PhoneInput
                                        value={ phone }
                                        onChange={ ( e ) => setPhone( e ) }
                                        defaultCountry="IN"
                                        disabled={ !isEditingPhone }
                                        className="submission-phone-input"
                                        international
                                        countryCallingCodeEditable={ false }
                                        withCountryCallingCode={ true }
                                    />
                                    <button
                                        className="ml-2 text-blue-500"
                                        onClick={ () => setIsEditingPhone( !isEditingPhone ) }
                                    >
                                        { isEditingPhone ? <EditSvgIcon /> : <SaveSvgIcon /> }
                                    </button>
                                </div>
                            </div>
                            { otpVisible && (
                                <>
                                    <div className="flex justify-center mb-4 transition-transform duration-500 transform translate-y-4">
                                        { otp.map( ( value, index ) => (
                                            <Input
                                                key={ index }
                                                type="text"
                                                maxLength={ 1 }
                                                value={ value }
                                                onChange={ ( e ) => handleOtpChange( e, index ) }
                                                ref={ ( el ) => ( inputRefs.current[index] = el ) }
                                                className="w-12 h-12 mx-1 text-center font-medium text-lg  border border-gray-300 rounded-lg"
                                            />
                                        ) ) }
                                    </div>
                                    <div className="text-center">
                                        { timer > 0 ? (
                                            `Resend OTP in ${ timer }s`
                                        ) : (
                                                <span
                                                    className={ `cursor-pointer ${ resendClickable ? "text-blue-500" : "text-gray-400"
                                                        }` }
                                                    onClick={ handleSentOtp }
                                            >
                                                    Resend OTP
                                                </span>
                                        ) }
                                    </div>
                                </>
                            ) }
                            <button
                                className="w-full text-white py-2 rounded-lg font-semibold text-lg flex justify-center items-center mt-4"
                                onClick={
                                    ( isEditingPhone || isEditingEmail ) && ( !requestedDataOTPEmail || !requestedDataOTPPhone )
                                        ? () => { }
                                        : otpVisible
                                            ? handleVerifyOTP
                                            : handleSentOtp
                                }
                                style={ {
                                    backgroundColor: otpVisible
                                        ? "#4CAF50"
                                        : isEditingPhone || isEditingEmail
                                            ? `${ clientConfig.primary_color }80`
                                            : clientConfig.primary_color,
                                    cursor:
                                        ( isEditingPhone || isEditingEmail ) && ( !requestedDataOTPEmail || !requestedDataOTPPhone )
                                            ? "not-allowed"
                                            : "pointer",
                                } }
                            >
                                { requestiongOTPEmail ||
                                    requestiongOTPPhone ||
                                    emailVerifying ||
                                    userInfoUpdating ||
                                    updatingStudent ||
                                    phoneVerifying ? (
                                    <Spin
                                        indicator={ <LoadingOutlined spin /> }
                                        size="large"
                                        className="text-white"
                                    />
                                ) : (
                                    buttonText
                                ) }
                            </button>
                            <p className="text-xs font-normal text-black mt-3">
                                <span className="font-bold">NOTE : </span>You won't be able to
                                change it after this confirming.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReSubmissionScreen;
