import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";
import { dropdownCategory, dropdownAction } from "../../utils/GoogleAnalytics/events/categories";
import "../../styles.css"
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const { Option } = Select;

const DropDownStepMultipleSelect = ( {
    question,
    selectedOptions,
    onNext,
    onBack,
    isPagination,
    isQuestionNavigation,
    isValidation,
    onClearAll,
    index
} ) => {
    const [isOptionSelected, setIsOptionSelected] = useState( false );
    const clientConfig = useSelector( ( state ) => state.clientConfiguration );

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedOptions?.selectedOptions || [],
        textResponse: selectedOptions?.textResponse || '',
    } );
    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    useEffect( () => {
        if ( userResponse?.selectedOptions?.length > 0 ) {
            setIsOptionSelected( true );
            isValidation( true );
        } else {
            setIsOptionSelected( false );
            isValidation( false );
        }
    }, [question] );

    const numericalDigits = question?.description.match( /\d+/g )?.map( Number );
    const numericalLimit = numericalDigits ? Math.max( ...numericalDigits ) : null;

    const handleSelect = ( selectedOptionIds ) => {
        if ( numericalLimit && selectedOptionIds.length > numericalLimit ) {
            return; 
        }

        const updatedUserResponse = {
            ...userResponse,
            selectedOptions: selectedOptionIds,
        };

        setUserResponse( updatedUserResponse );
        SaveUserResponseLocalStorage( { question: question, userResponse: updatedUserResponse, isPagination: isPagination, index: index } )

        setIsOptionSelected( selectedOptionIds.length > 0 );
        isValidation( selectedOptionIds.length > 0 );

        TrackGoogleAnalyticsEvent( dropdownCategory, dropdownAction );
    };

    useEffect( () => {
        setUserResponse( {
            selectedOptions: selectedOptions?.selectedOptions || [],
            textResponse: selectedOptions?.textResponse || '',
        } );
    }, [question, selectedOptions] );

    const handleKeyDown = ( e ) => {
        if ( e.key === 'Enter' ) {
            onNext();
        }
    };

    const handleClearAll = () => {
        const updatedUserResponse = {
            ...userResponse,
            selectedOptions: [],
        };
        setUserResponse( updatedUserResponse );
        SaveUserResponseLocalStorage( { question: question, userResponse: updatedUserResponse, isPagination: isPagination, index: index } )
        onClearAll();
        isValidation( false );
        setIsOptionSelected( false );
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <Select
                    className="w-full dynamic-dropdown"
                    value={ userResponse.selectedOptions }
                    onChange={ handleSelect } 
                    showSearch={ true }
                    mode="multiple"
                    placeholder={ question?.placeholder_text }
                    filterOption={ ( input, option ) =>
                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                    }
                    onKeyDown={ handleKeyDown }
                    tabIndex={ 0 }
                >
                    { question?.options?.map( ( option ) => (
                        <Option key={ option.id } value={ option.id }>
                            { option.value }
                        </Option>
                    ) ) }
                </Select>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            { question?.question_optional === "optional" && (
                                <button className="skip-button" onClick={ onNext }>
                                    Skip
                                </button>
                            ) }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isOptionSelected ? onNext : null }
                                style={ {
                                    backgroundColor: isOptionSelected ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: isOptionSelected ? 'pointer' : 'not-allowed'
                                } }

                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default DropDownStepMultipleSelect;
