import { useState } from "react";
import axios from "axios";
import { BASE_URL, FETCH_DETAILS_FROM_EMAIL } from "../utils/constants/constants";
import { notification } from "antd";

const useFetchStudentDataEmail = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FETCH_DATA = `${ BASE_URL }${ FETCH_DETAILS_FROM_EMAIL }`

    const FetchStudentDataEmail = async ( email, client_name ) => {

        setIsLoading( true );

        try {
            const res = await axios.get( `${ FETCH_DATA }/?email=${ email }&client=${ client_name }` );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            notification.error( {
                message: err?.message
            } )
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchStudentDataEmail };
};

export default useFetchStudentDataEmail;