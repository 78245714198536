import React from 'react'
import Lottie from 'lottie-react';
import thankYouanimation from '../../assets/Animation/thankyou_animation.json'
import { useMedia } from 'react-use';

const ThankYouPageApplicationForm = () => {
    const isWide = useMedia( '(max-width: 500px)' );

    return (
        <>
            {
                isWide ?
                    <div className="h-screen  absolute bottom-0">
                        <Lottie animationData={ thankYouanimation } loop={ 0 } autoplay style={ { width: 'screen', height: 'screen' } } />
                    </div>
                    :
                    <div className="min-h-screen absolute bottom-0">
                        <Lottie animationData={ thankYouanimation } loop={ 0 } autoplay style={ { width: '800px', height: 'screen' } } />
                    </div>
            }

            <div className="text-center lg:w-[90%] sm:w-full flex flex-col justify-around items-center font-poppins h-full">
                <div className='flex flex-col items-center '>
                    <p className="lg:text-5xl sm:text-2xl text-black font-semibold mb-4">Congratulations!</p>
                    <p className="lg:text-2xl sm:text-lg text-black font-semibold lg:w-2/3 sm:w-full mb-4">Thank you for filling out the form. We'll send you the test link on the registered email.</p>
                </div>
            </div>
        </>
    )
}

export default ThankYouPageApplicationForm;