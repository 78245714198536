import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Ensure base styles are imported
import './PhoneInputStyles.css'; // Adjust the path if necessary

const CustomPhoneInput = ( props ) => {
    return (
        <div className="scoped-phone-input">
            <PhoneInput { ...props } className={ `phone-input-custom ${ props.className || '' }` } defaultCountry={ props.countryCode } />
        </div>
    );
};

export default CustomPhoneInput;
