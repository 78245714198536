import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { AUTH_URL_IN } from '../utils/constants/constants';

const useFetchCourses = ( ) => {
  const [data, setData] = useState( null );
  const [isError, setIsError] = useState( false );
  const [isLoading, setIsLoading] = useState( true );
  const isFirstMount = useRef( true ); // Initialize a useRef

  useEffect( () => {
    if ( isFirstMount.current ) { 

        const fetchData = async () => {
          setIsLoading( true );
          try {
            const response = await axios.get( `${AUTH_URL_IN}/profile/streams-and-specializations/options` );
            setData( response.data );
          } catch ( error ) {
            setIsError( true );
          } finally {
            setIsLoading( false );
          }
        };

        fetchData();

        isFirstMount.current = false; // Set it to false after the first valid API call
    }
  }, [] ); // Dependency on id, so the effect runs whenever id changes

  return { data, isLoading, isError };
};

export default useFetchCourses;
