import React, { useState } from "react";
import { Slider } from "antd";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const marks = {};
for ( let i = 1; i <= 10; i++ ) {
    marks[i * 1] = `${ i * 1 }`;
}

const RangeTypeQuestion = ( {
    question,
    onNext,
    onBack,
    selectedAnswer,
    isPagination,
    isQuestionNavigation,
    isValidation,
    index
} ) => {
    const clientConfig = useSelector( state => state.clientConfiguration );

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedAnswer?.selectedOptions || [],
        textResponse: selectedAnswer?.textResponse || '',
    } );

    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const onChange = ( newValue ) => {

        const userSelectedAnswer = {
            ...userResponse,
            textResponse: newValue, // Update selectedOptions
        };

        setUserResponse( userSelectedAnswer );
        SaveUserResponseLocalStorage( { question: question, userResponse: userSelectedAnswer, isPagination: isPagination, index: index } )
        isValidation( true );
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div className={`flex flex-col justify-between ${isPagination ? "mb-4" : "mt-2 lg:h-full" }`}>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div className="justify-content space-between w-full mt-5 mb-5">
                    <Slider marks={ marks } defaultValue={ userResponse.textResponse } step={ 1 } max={ 10 } min={ 1 } onChange={ onChange } />
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ userResponse.textResponse ? onNext : null }
                                style={ {
                                    backgroundColor: userResponse.textResponse ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: userResponse.textResponse ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default RangeTypeQuestion;
