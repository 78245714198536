import React, { useState, useEffect } from 'react';
import { notification, Spin } from 'antd';
import { useSelector } from 'react-redux';
import ThankYouPageDynamicComponent from './ThankYouPageDynamicComponent';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import ThankYouPageApplicationForm from './ThankYouPageApplicationForm';


const ThankYouPageComponentOne = () => {

    const [showDownloadButton, setShowDownloadButton] = useState( false );
    const [showBookingURL, setShowBookingURL] = useState( false );
    const [isApplicationForm, setIsApplicationForm] = useState( false );

    const [studentRollNumber, setStudentRollNumber] = useState( null );
    const [isDownloaded, setIsDownloaded] = useState( false );
    const [reportLink, setReportLink] = useState( null );

    const formConfigRedux = useSelector( state => state.formConfiguration );

    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
    const flowData = sessionStorage.getItem( 'flowData' );
    const parsedFlowData = flowData ? JSON.parse( flowData ) : [];

    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig?.form_id;
    const bookingLink = formConfig?.booking_url;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    useEffect( () => {
        if ( parsedFlowData?.some( ( item ) => item.block_type === 'pdf_view' ) ) {
            setShowDownloadButton( true );
        }
        if ( parsedFlowData?.some( ( item ) => item.block_type === 'booking_view' ) ) {
            setShowBookingURL( true );
        }
        if ( parsedFlowData?.some( ( item ) => item.block_type === 'application_form_view' ) ) {
            setIsApplicationForm( true );
        }
    }, [parsedFlowData] );


    const handleBooking = () => {
        if ( bookingLink ) {
            window.open( `${ bookingLink }/?name=${ student_name }&email=${ student_email }`, '_blank' )
        } else {
            notification.error( { message: 'Oops!', description: 'Please try again' } )
        }
    };

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid );
        }
    }, [studentRollNumber] );

    useEffect( () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
    }, [] );

    const handleDownloadReport = () => {
        setIsDownloaded( true )
    };

    useEffect( () => {
        if ( pdfReport && reportLink === null ) {
            setReportLink( pdfReport );
        }
    }, [pdfReport] );

    useEffect( () => {
        if ( reportLink && !pdfReportGenerating && isDownloaded ) {
            setIsDownloaded( false );
            try {
                const url = new URL( reportLink );
                const downloadLink = document.createElement( "a" );
                downloadLink.style.display = "none";
                downloadLink.href = reportLink;

                // Append the anchor element to the document body
                document.body.appendChild( downloadLink );

                // Trigger a click event on the anchor to start the download
                downloadLink.click();

                // Remove the anchor from the document
                document.body.removeChild( downloadLink );
            } catch ( e ) {
                console.error( 'Invalid PDF report URL:', reportLink );
            }
        }
    }, [reportLink, pdfReportGenerating, isDownloaded] );


    return (
        <>
            {
                pdfReportGenerating && isDownloaded &&
                <div div className="overlay">
                    <Spin size="large" />
                </div>
            }
            { isApplicationForm ?
                <ThankYouPageApplicationForm /> :
                <ThankYouPageDynamicComponent
                    handleReport={ handleDownloadReport }
                    handlementorshipbooking={ handleBooking }
                    isBookingUrlVisible={ showBookingURL }
                    isDownloadReportButtonVisible={ showDownloadButton }
                />
            }
        </>
    );
};

export default ThankYouPageComponentOne;