import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { numberTypeCategory, numberTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import "../../styles.css";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const PhoneNumberInputStep = ( { question, selectedAnswer, onNext, onBack, isPagination, isQuestionNavigation, isValidation, index } ) => {

    const [isValid, setIsValid] = useState( false );
    const [showError, setShowError] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );
    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedAnswer?.selectedOptions || [],
        textResponse: selectedAnswer?.textResponse || '',
    } );


    useEffect( () => {
        if ( selectedAnswer?.textResponse ) {
            setUserResponse( {
                selectedOptions: selectedAnswer?.selectedOptions || [],
                textResponse: selectedAnswer?.textResponse || '',
            } );
            if ( isValidPhoneNumber( selectedAnswer?.textResponse ) ) {
                setIsValid( true );
                isValidation( true );
            } else {
                setIsValid( false );
                isValidation( false );
            }
        }
    }, [question] );

    const handleInputChange = ( e ) => {
        const value = e || "";
        if ( isValidPhoneNumber( value ) ) {
            setIsValid( true );
            isValidation( true );
        } else {
            setIsValid( false );
            isValidation( false );
        }

        const userSelectedAnswer = {
            ...userResponse,
            textResponse: value, // Update selectedOptions
        };
        setUserResponse( userSelectedAnswer );
        SaveUserResponseLocalStorage( { question: question, userResponse: userSelectedAnswer, isPagination: isPagination, index: index } )
        TrackGoogleAnalyticsEvent( numberTypeCategory, numberTypeAction );
    };

    const handleBlur = () => {
        if ( !isValidPhoneNumber( userResponse?.textResponse ) ) {
            setShowError( true );
        } else {
            setShowError( false );
        }
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div className="mt-2">
                    <PhoneInput
                        international
                        className="sm:w-full lg:w-1/2 rounded-md"
                        defaultCountry="IN"
                        value={ userResponse?.textResponse }
                        onChange={ ( e ) => handleInputChange( e ) }
                        onBlur={ handleBlur }
                        placeholder={ question?.placeholder_text }
                        countryCallingCodeEditable={ false }
                    />
                    {
                        showError &&
                        <p className="text-red-500 text-xs left-0 mt-2">
                            Invalid Phone Number. Please enter a Phone Number.
                        </p>
                    }
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isValid ? onNext : null }
                                style={ {
                                    backgroundColor: isValid ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: isValid ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default PhoneNumberInputStep;
