import React, { useState } from "react";
import axios from "axios";
import { BASE_URL, VERIFY_COUPON_CODE } from "../utils/constants/constants";

const useVerifyCouponCode = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const verifyCouponCode = async ( { student_email, couponCode, userId } ) => {
        if ( !student_email || !couponCode ) return;
        setIsLoading( true );
        setError( null );
        const API_URL = userId
            ? `${ BASE_URL }${ VERIFY_COUPON_CODE }${ student_email }/${ couponCode }?student_id=${ userId }`
            : `${ BASE_URL }${ VERIFY_COUPON_CODE }${ student_email }/${ couponCode }`;

        try {
        const response = await axios.get(
            API_URL
        );

          setResponse( response );
          setIsLoading( false );
      } catch ( err ) {
          setError( err );
          setIsLoading( false );
      }
  };

    return { response, isLoading, error, verifyCouponCode };
};

export default useVerifyCouponCode;
