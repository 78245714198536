import React, { useState, useEffect } from "react";
import { Select, notification } from "antd";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import useFetchCourses from "../../api/FetchCourses";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";
import "../../styles.css"

const { Option } = Select;

const DropDownStepSingleSelect = ( { question, selectedOptions, onNext, onBack, courseName, selectedCourseName, isPagination, isQuestionNavigation, isValidation, onClearAll, index } ) => {

    const [isOptionSelected, setIsOptionSelected] = useState( false );
    const [courses, setCourses] = useState();
    const [courseSelected, setCourseSelected] = useState( selectedCourseName || [] );
    const [isCourseOptions, setIsCourseOptions] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );
    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedOptions?.selectedOptions || [],
        textResponse: selectedOptions?.textResponse || '',
    } );


    const { data } = useFetchCourses();

    const handleSelect = ( selectedOptionIds ) => {
        if ( selectedOptionIds.length > 1 ) {
            return
        }

        const updatedUserResponse = {
            ...userResponse,
            selectedOptions: selectedOptionIds,
        };

        setUserResponse( updatedUserResponse );
        SaveUserResponseLocalStorage( { question: question, userResponse: updatedUserResponse, isPagination: isPagination, index: index } )
        if ( selectedOptionIds.length > 0 ) {
            setIsOptionSelected( true );
            isValidation( true )
        } else {
            setIsOptionSelected( false );
            isValidation( false )
        }
    };

    const handleCourseSelect = ( value ) => {
        setCourseSelected( value )
        courseName( value );
        setIsOptionSelected( value != null );
        isValidation( value != null );
    };

    useEffect( () => {
        if ( userResponse?.selectedOptions?.length > 0 ) {
            setIsOptionSelected( true );
            isValidation( true );
        } else {
            setIsOptionSelected( false );
            isValidation( false );
        }
    }, [question] );


    useEffect( () => {
        setUserResponse( {
            selectedOptions: selectedOptions?.selectedOptions || [],
            textResponse: selectedOptions?.textResponse || '',
        } );
    }, [question] );

    useEffect( () => {
        if ( question?.text && question.text.includes( "What course are you pursuing?" ) ) {
            setCourses( data?.data.streams_and_specializations );
            setIsCourseOptions( true );
        }
    }, [question, data] );

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleKeyDown = ( e ) => {
        if ( e.key === 'Enter' ) {
            e.preventDefault(); 
            if ( isQuestionNavigation ) {
                notification.info( {
                    message: 'Please click on Save & Next.'
                } )
            }
        }
    };

    const handleClearAll = () => {
        const updatedUserResponse = {
            ...userResponse,
            selectedOptions: [],
        };
        setUserResponse( updatedUserResponse );
        SaveUserResponseLocalStorage( { question: question, userResponse: updatedUserResponse, isPagination: isPagination, index: index } )
        handleCourseSelect( [] )
        onClearAll()
        setIsOptionSelected( false );
    }

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                {
                    !isCourseOptions ?
                        <>
                            <Select
                                className="mb-3 mt-2 text-xl lg:w-full sm:w-full"
                                value={ userResponse.selectedOptions }
                                showSearch={ true }
                                filterOption={ ( input, option ) =>
                                    option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                                }
                                onChange={ handleSelect }
                                placeholder={ question?.placeholder_text }
                                onKeyDown={ handleKeyDown }
                                mode="tags"
                            >
                                { question?.options?.map( ( option ) => (
                                    <Option key={ option.id } value={ option.id }>
                                        { option.value }
                                    </Option>
                                ) ) }
                            </Select>
                        </>
                        :
                        <>
                            <Select
                                showSearch={ true }
                                className="mb-3 mt-2 text-xl lg:w-1/2 sm:w-full"
                                value={ courseSelected }
                                onChange={ handleCourseSelect }
                                placeholder={ question?.placeholder_text }
                            >
                                { courses?.map( ( course, index ) => (
                                    <Option key={ index } value={ course }>
                                        { course }
                                    </Option>
                                ) ) }
                            </Select>
                        </>
                }
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isOptionSelected ? onNext : null }
                                style={ {
                                    backgroundColor: isOptionSelected ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: isOptionSelected ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default DropDownStepSingleSelect;
