import React, { useState, useEffect } from "react";
import { Select } from "antd";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import { useSelector } from 'react-redux';
import useFetchAllSubjects from "../../api/FetchAllSubjects";
import SubmitButton from "../SubmitButton/SubmitButton";
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const { Option } = Select;

const SubjectSelectionQuestion = ( {
    question,
    selectedAnswer,
    onNext,
    onBack,
    isPagination,
    isQuestionNavigation,
    isValidation,
    onClearAll,
    index
} ) => {

    const [isOptionSelected, setIsOptionSelected] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );
    const [listOfSubjects, setListOfSubjects] = useState( null );
    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedAnswer?.selectedOptions || [],
        textResponse: selectedAnswer?.textResponse || [],
    } );

    const { response: AllSubjects, isLoading: SubjectsLoading, FetchSubjects } = useFetchAllSubjects();

    useEffect( () => {
        FetchSubjects();
    }, [] );

    useEffect( () => {
        setListOfSubjects( AllSubjects );
    }, [AllSubjects] );

    const handleSubjectChange = ( value ) => {
        const updatedUserResponse = {
            ...userResponse,
            textResponse: value,
        };
        setUserResponse( updatedUserResponse );
        SaveUserResponseLocalStorage( { question: question, userResponse: updatedUserResponse, isPagination: isPagination, index: index, userResponseString: true } );
        setIsOptionSelected( value?.length > 0 );
        isValidation( value?.length > 0 );
    };

    useEffect( () => {
        setUserResponse( {
            selectedOptions: selectedAnswer?.selectedOptions || [],
            textResponse: Array.isArray( selectedAnswer?.textResponse )
                ? selectedAnswer.textResponse
                : selectedAnswer?.textResponse?.split( "," ).map( item => item.trim() ) || [],
        } );
    }, [question] );

    useEffect( () => {
        if ( userResponse?.textResponse?.length > 0 ) {
            setIsOptionSelected( true );
            isValidation( true );
        } else {
            setIsOptionSelected( false );
            isValidation( false );
        }
    }, [userResponse] );

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleClearAll = () => {
        handleSubjectChange( [] );
        onClearAll();
    };

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div className="space-y-2 mt-2">
                    <Select
                        mode="multiple"
                        showSearch={ true }
                        placeholder="Select Your Subject(s)"
                        className="address-dropdown mb-3 text-lg w-full"
                        value={ userResponse.textResponse }
                        onChange={ handleSubjectChange }
                    >
                        { listOfSubjects?.map( ( state, index ) => (
                            <Option key={ index } value={ state }>
                                { state }
                            </Option>
                        ) ) }
                    </Select>
                </div>
            </div>
            { isPagination ? (
                <></>
            ) : isQuestionNavigation ? (
                <>
                        <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                    </>
                ) : (
                    <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            { question?.question_optional === "optional" && (
                                <button className="skip-button" onClick={ onNext }>
                                    Skip
                                </button>
                            ) }
                            { perQuestionTimer ? (
                                <></>
                            ) : (
                                <button className="back-button" onClick={ onBack }>
                                    Back
                                </button>
                            ) }
                            <button
                                className="next-button"
                                onClick={ isOptionSelected ? onNext : null }
                                style={ {
                                    backgroundColor: isOptionSelected
                                        ? `${ clientConfig.primary_color }`
                                        : `${ clientConfig.primary_color }80`,
                                    cursor: isOptionSelected ? "pointer" : "not-allowed"
                                } }
                            >
                                Next
                            </button>
                        </div>
            ) }
        </div>
    );
};

export default SubjectSelectionQuestion;
