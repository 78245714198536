import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { numberTypeCategory, numberTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import { useSelector } from 'react-redux';
import SubmitButton from "../SubmitButton/SubmitButton";
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const FlexibleInputStep = ( { question, selectedAnswer, onNext, onBack, isPagination, isQuestionNavigation, isValidation, onClearAll, index } ) => {
    const [isAnswerNumeric, setIsAnswerNumeric] = useState( false );

    const clientConfig = useSelector( state => state.clientConfiguration );

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedAnswer?.selectedOptions || [],
        textResponse: selectedAnswer?.textResponse || '',
    } );

    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    useEffect( () => {
        if ( selectedAnswer?.textResponse ) {
            setIsAnswerNumeric( true );
            isValidation( true );
            setUserResponse( {
                selectedOptions: selectedAnswer?.selectedOptions || [],
                textResponse: selectedAnswer?.textResponse || '',
            } );
        } else {
            setIsAnswerNumeric( false )
            isValidation( false );
        }
    }, [question] )

    const handleInputChange = ( e ) => {
        const inputValue = e.target.value;

        const userSelectedAnswer = {
            ...userResponse,
            textResponse: inputValue,
        };

        setUserResponse( userSelectedAnswer );
        SaveUserResponseLocalStorage( { question: question, userResponse: userSelectedAnswer, isPagination: isPagination, index: index } )
        if ( inputValue ) {
            setIsAnswerNumeric( true );
            isValidation( true );
        } else {
            setIsAnswerNumeric( false );
            isValidation( false );
        }
        TrackGoogleAnalyticsEvent( numberTypeCategory, numberTypeAction );
    };


    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleClearAll = () => {
        onClearAll()
    }

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div>
                    <input
                        type="text"
                        className="w-1/2 sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                        value={ userResponse.textResponse }
                        onChange={ ( e ) => handleInputChange( e, question.validation ) }
                        placeholder={ question?.placeholder_text }
                    />
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {/* { question?.question_optional === "optional" && (
                                <button className="skip-button" onClick={ onNext }>
                                    Skip
                                </button>
                            ) } */}
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ ( isAnswerNumeric || question?.question_optional === "optional" ) ? onNext : null }
                                style={ {
                                    backgroundColor: ( isAnswerNumeric || question?.question_optional === "optional" ) ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: ( isAnswerNumeric || question?.question_optional === "optional" ) ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default FlexibleInputStep;

