import { useState } from "react";
import axios from "axios";
import {
    STRIDE_AUTH_URL,
    GENERATE_PDF_REPORT,
    STAGING_API_BASE_URL,
    BASE_URL
} from "../utils/constants/constants";

const useGeneratePdfReport = () => {
    const [data, setData] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const [error, setError] = useState( null );

    const generatePdfReport = async ( studentRollNumber, formId ) => {
        setIsLoading( true );
        setError( null );

        let PDF_BASE_URL =
            window.location.hostname === "localhost" || window.location.hostname.includes( "staging" )
                ? `${ STAGING_API_BASE_URL }/v1/quiz-backend`
                : BASE_URL;

        const requestBody = {
            form_ids: [formId],
        };

        try {
            const response = await axios.post(
                `${ PDF_BASE_URL }${ GENERATE_PDF_REPORT }?student_roll_number=${ studentRollNumber }&version=v2`,
                requestBody
            );
            setData( response.data );
        } catch ( err ) {
            try {
                PDF_BASE_URL = STRIDE_AUTH_URL;
                const fallbackResponse = await axios.post(
                    `${ PDF_BASE_URL }${ GENERATE_PDF_REPORT }?student_roll_number=${ studentRollNumber }&version=v2`,
                    requestBody
                );
                setData( fallbackResponse.data );
            } catch ( fallbackError ) {
                setError( fallbackError );
            }
        } finally {
            setIsLoading( false );
        }
    };

    return { data, isLoading, error, generatePdfReport };
};

export default useGeneratePdfReport;
