import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { multipleChoiceCategory, multipleChoiceAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const CustomCheckbox = ( { option, selected, onSelect, onNext } ) => {
    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };

    return (
        <label
            className="flex items-center cursor-pointer bg-white p-2 rounded-md p-4 border-2 mt-2 lg:mr-6"
            htmlFor={ `checkbox-${ option.id }` }
        >
            <Checkbox
                id={ `checkbox-${ option.id }` }
                checked={ selected?.includes( option.id ) }
                onChange={ () => onSelect( option ) }
                onKeyDown={ handleKeyDown }
                tabIndex={ 0 }
            />
            <span className="ml-2">{ option.value }</span>
        </label>
    );
};

const MultiChoiceStep = ( {
    question,
    onNext,
    onBack,
    selectedOptions,
    isPagination,
    isQuestionNavigation,
    isValidation,
    index
} ) => {
    // State to manage user responses
    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedOptions?.selectedOptions || [], // Ensures selectedOptions is an array
        textResponse: selectedOptions?.textResponse || '',
    } );

    const clientConfig = useSelector( ( state ) => state.clientConfiguration );

    // Extract numerical digit from the question description
    const numericalDigitMatch = question?.description.match( /\d+/ );
    const numericalDigit = numericalDigitMatch ? parseInt( numericalDigitMatch[0], 10 ) : null;
    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    // Function to handle the selection of options
    const handleSelect = ( option ) => {
        let updatedOptions = [...userResponse.selectedOptions]; // Spread operator ensures array copy
        if ( updatedOptions.includes( option.id ) ) {
            // Remove the option if it's already selected
            updatedOptions = updatedOptions.filter( ( id ) => id !== option.id );
        } else {
            // Add the option if it's not selected and within the numerical limit
            if ( !numericalDigit || updatedOptions.length < numericalDigit ) {
                updatedOptions.push( option.id );
            }
        }

        const userSelectedOption = {
            ...userResponse,
            selectedOptions: updatedOptions, // Update selectedOptions
        };

        // Update the state and trigger onSelect
        setUserResponse( userSelectedOption );
        SaveUserResponseLocalStorage( { question: question, userResponse: userSelectedOption, isPagination: isPagination, index: index } )
        TrackGoogleAnalyticsEvent( multipleChoiceCategory, multipleChoiceAction );
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    // Clear all selected options
    const handleClearAll = () => {
        const clearedResponse = {
            ...userResponse,
            selectedOptions: [],
        };
        setUserResponse( clearedResponse );
        SaveUserResponseLocalStorage( { question: question, userResponse: clearedResponse, isPagination: isPagination, index: index } )
    };

    useEffect( () => {
        setUserResponse( {
            selectedOptions: selectedOptions?.selectedOptions || [],
            textResponse: selectedOptions?.textResponse || '',
        } );
    }, [question] );

    useEffect( () => {
        if ( userResponse?.selectedOptions?.length ) {
            isValidation( true );
        } else {
            isValidation( false );
        }
    }, [userResponse] );

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col lg:h-full overflow-y-auto">
                <QuestionAndDescription question={ question } />
                <div className="justify-content space-between w-full">
                    { question?.options?.map( ( option ) => (
                        <CustomCheckbox
                            key={ option.id }
                            option={ option }
                            selected={ userResponse?.selectedOptions }
                            onSelect={ handleSelect }
                            onNext={ onNext }
                        />
                    ) ) }
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext }>
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack }>
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ userResponse?.selectedOptions?.length ? onNext : null }
                                style={ {
                                    backgroundColor: userResponse?.selectedOptions?.length ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: userResponse?.selectedOptions?.length ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default MultiChoiceStep;
