import React, { useEffect, useState } from "react";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from "react-redux";
import { dynamicData } from "../../helpers/data";
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const OrderTypeQuestion = ( {
    question,
    onNext,
    onBack,
    selectedOptions,
    isPagination,
    isQuestionNavigation,
    isValidation,
    onClearAll,
    index,
} ) => {
    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedOptions?.selectedOptions || [],
        textResponse: selectedOptions?.textResponse || "",
    } );

    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();
    const clientConfig = useSelector( ( state ) => state.clientConfiguration );
    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    // Update state when the question changes
    useEffect( () => {
        const initialSelectedOptions = selectedOptions?.selectedOptions?.length
            ? selectedOptions.selectedOptions
            : question?.options?.map( ( option ) => option.id ) || [];

        const newUserResponse = {
            selectedOptions: initialSelectedOptions,
            textResponse: selectedOptions?.textResponse || "",
        }

        setUserResponse( newUserResponse );
        SaveUserResponseLocalStorage( {
            question,
            userResponse: newUserResponse,
            isPagination,
            index,
        } );
    }, [question, selectedOptions] );

    const handleOrderChange = ( newOrder ) => {
        const updatedResponse = {
            ...userResponse,
            selectedOptions: newOrder,
        };
        setUserResponse( updatedResponse );
        SaveUserResponseLocalStorage( {
            question,
            userResponse: updatedResponse,
            isPagination,
            index,
        } );
    };

    const handleClearAll = () => {
        onClearAll();
    };

    const handleNext = () => {
        onNext();
        isValidation( true );
    };

    return (
        <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col lg:h-full overflow-y-auto">
                <QuestionAndDescription question={ question } />
                <div className="justify-content space-between w-full">
                    <DragDropList
                        options={ question?.options }
                        orderIds={ userResponse.selectedOptions }
                        onOrderChange={ handleOrderChange }
                    />
                </div>
            </div>
            { isPagination ? null : isQuestionNavigation ? (
                <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
            ) : (
                <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                        { question?.question_optional === "optional" && (
                            <button className="skip-button" onClick={ onNext }>
                            Skip
                        </button>
                    ) }
                        { !perQuestionTimer && (
                            <button className="back-button" onClick={ onBack }>
                                Back
                            </button>
                        ) }
                    <button
                        className="next-button"
                        onClick={ handleNext }
                            style={ {
                                backgroundColor: clientConfig.primary_color,
                                cursor: "pointer",
                            } }
                    >
                        Next
                    </button>
                </div>
            ) }
        </div>
    );
};

const DragDropList = ( { options, orderIds, onOrderChange } ) => {
    const [items, setItems] = useState( [] );

    useEffect( () => {
        const orderedItems = orderIds
            .map( ( id ) => options.find( ( option ) => option.id === id ) )
            .filter( Boolean );
        setItems( orderedItems );
    }, [options, orderIds] );

    const handleDragStart = ( e, index ) => {
        e.dataTransfer.setData( "text/plain", index );
    };

    const handleDrop = ( e, targetIndex ) => {
        const draggedIndex = parseInt( e.dataTransfer.getData( "text/plain" ), 10 );
        if ( draggedIndex === targetIndex ) return;

        const newItems = [...items];
        const [draggedItem] = newItems.splice( draggedIndex, 1 );
        newItems.splice( targetIndex, 0, draggedItem );

        setItems( newItems );
        onOrderChange( newItems.map( ( item ) => item.id ) );
    };

    const handleDragOver = ( e ) => {
        e.preventDefault();
    };

    return (
        <div className="flex flex-col space-y-2">
            { items.map( ( item, index ) => (
                <div
                    key={ item.id }
                    className="flex items-center p-3 border border-gray-300 rounded-md"
                    draggable
                    onDragStart={ ( e ) => handleDragStart( e, index ) }
                    onDragOver={ handleDragOver }
                    onDrop={ ( e ) => handleDrop( e, index ) }
                >
                    <div className="mr-4 cursor-move">&#9776;</div>
                    { item.value }
                </div>
            ) ) }
        </div>
    );
};

export default OrderTypeQuestion;
