import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { indianCities } from "../../helpers/data";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import { useSelector } from 'react-redux';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const { Option } = Select;

const IndianCitiesSelectionQuestion = ( { question, selectedAnswer, onNext, onBack, isPagination, isQuestionNavigation, isValidation, index } ) => {

    const [isOptionSelected, setIsOptionSelected] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedAnswer?.selectedOptions || [],
        textResponse: selectedAnswer?.textResponse || '',
    } );
    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const handleIndianCityChange = ( value ) => {

        const userSelectedAnswer = {
            ...userResponse,
            textResponse: value,
        };

        setUserResponse( userSelectedAnswer );
        SaveUserResponseLocalStorage( { question: question, userResponse: userSelectedAnswer, isPagination: isPagination, index: index } )
        setIsOptionSelected( true );
        isValidation( true );
    };

    useEffect( () => {
        if ( selectedAnswer && selectedAnswer.textResponse && indianCities.includes( selectedAnswer.textResponse ) ) {
            setUserResponse( selectedAnswer );
            setIsOptionSelected( true );
            isValidation( true );
        } else {
            isValidation( false );
        }
    }, [question] );

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div className="space-y-2 mt-4">
                    <Select
                        showSearch={ true }
                        placeholder="Enter your City"
                        className="w-1/2 sm:w-full lg:w-1/2 address-dropdown"
                        value={ userResponse.textResponse }
                        onChange={ handleIndianCityChange }
                    >
                        { indianCities.map( ( city, index ) => (
                            <Option key={ index } value={ city }>
                                { city }
                            </Option>
                        ) ) }
                    </Select>
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                        {
                            question?.question_optional === "optional" && (
                                <button className="skip-button" onClick={ onNext } >
                                    Skip
                                </button>
                            )
                        }
                        {
                            perQuestionTimer ?
                                <></> :
                                <button className="back-button" onClick={ onBack } >
                                    Back
                                </button>

                        }
                        <button
                            className="next-button"
                            onClick={ isOptionSelected ? onNext : null }
                            style={ {
                                backgroundColor: isOptionSelected ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                cursor: isOptionSelected ? 'pointer' : 'not-allowed'
                            } }
                        >
                            Next
                        </button>
                    </div>
            }
        </div>
    );
};

export default IndianCitiesSelectionQuestion;