import React, { useState, useEffect } from "react";
import { Select, Input, Spin } from 'antd';
import "../../styles.css"
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import { dynamicData } from "../../helpers/data";
import useFetchAllCountries from "../../api/FetchAllCountries";
import useFetchAllStates from "../../api/FetchAllStates";
import useFetchAllCities from '../../api/FetchAllCities';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const { Option } = Select;

const AddressTypeQuestion = ( { question, isPagination, selectedAnswer, isQuestionNavigation, onNext, onBack, onClearAll, index } ) => {

    const clientConfig = useSelector( state => state.clientConfiguration );
    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();
    console.log( 'selectedAnswer', selectedAnswer );

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedAnswer?.selectedOptions || [],
        textResponse: selectedAnswer?.textResponse || {
            houseNumber: '',
            country: '',
            state: '',
            city: '',
            pincode: '',
        },
    } );

    const [isAnswerCompleted, setIsAnswerCompleted] = useState( false );
    const [listOfCountries, setListOfCountries] = useState( [] );
    const [listOfStates, setListOfStates] = useState( [] );
    const [listOfCities, setListOfCities] = useState( [] );

    // Validation states for required fields
    const [countryError, setCountryError] = useState( false );
    const [stateError, setStateError] = useState( false );

    const { response: allCountries, error: countriesError, isLoading: countriesLoading, FetchCountries } = useFetchAllCountries();
    const { response: allStates, error: statesError, isLoading: statesLoading, FetchStates } = useFetchAllStates();
    const { response: allCities, error: citiesError, isLoading: citiesLoading, FetchCities } = useFetchAllCities();

    useEffect( () => {
        FetchCountries();
    }, [] );

    useEffect( () => {
        let updatedTextResponse;

        if ( typeof selectedAnswer?.textResponse === 'object' ) {
            // If textResponse is an object, use it as it is
            updatedTextResponse = selectedAnswer?.textResponse || {
                houseNumber: '',
                country: '',
                state: '',
                city: '',
                pincode: '',
            };
        } else if ( typeof selectedAnswer?.textResponse === 'string' ) {
            // If textResponse is a string, split and map to the address structure
            const addressString = selectedAnswer?.textResponse || "";
            const parts = addressString.split( ',' ).map( part => part.trim() );

            updatedTextResponse = {
                houseNumber: parts[0] || "",
                city: parts[1] || "",
                state: parts[2] || "",
                country: parts[3] || "",
                pincode: parts[4] || "",
            };
        }

        setUserResponse( {
            selectedOptions: selectedAnswer?.selectedOptions || [],
            textResponse: updatedTextResponse,
        } );
    }, [question] );


    useEffect( () => {
        if ( userResponse?.textResponse ) {
            SaveUserResponseLocalStorage( { question: question, userResponse: userResponse, isPagination: isPagination, index: index, isAddress: true } )
        }
    }, [userResponse] );

    useEffect( () => {
        if ( userResponse?.textResponse?.country ) {
            FetchStates( userResponse?.textResponse?.country );
        }
    }, [userResponse?.textResponse?.country] );

    useEffect( () => {
        if ( userResponse?.textResponse?.state ) {
            FetchCities( userResponse?.textResponse?.country, userResponse?.textResponse?.state );
        }
    }, [userResponse?.textResponse?.state] );

    useEffect( () => {
        if ( allCountries && allCountries.data && allCountries.data.countries ) {
            setListOfCountries( allCountries.data.countries );
        }
    }, [allCountries] );

    useEffect( () => {
        if ( allStates && allStates.data && allStates.data.states ) {
            setListOfStates( allStates.data.states );
        }
    }, [allStates] );

    useEffect( () => {
        if ( allCities && allCities.data && allCities.data.cities ) {
            setListOfCities( allCities.data.cities );
        }
    }, [allCities] );

    useEffect( () => {
        // If no states and cities exist, allow user to move forward
        if ( userResponse?.textResponse?.country && listOfStates.length === 0 && listOfCities.length === 0 ) {
            setIsAnswerCompleted( true );
        }
        // If country, state, and pincode are selected
        else if ( userResponse?.textResponse?.country && userResponse.textResponse.state ) {
            setIsAnswerCompleted( true );
        } else {
            setIsAnswerCompleted( false );
        }
    }, [userResponse?.textResponse?.country, userResponse?.textResponse?.state, listOfStates, listOfCities] );

    const handleAddressChange = ( key, value ) => {
        const newTextResponse = {
            ...userResponse.textResponse,
            [key]: value,
        };

        setUserResponse( ( prevUserResponse ) => ( {
            ...prevUserResponse,
            textResponse: newTextResponse,
        } ) );

        // Clear validation error when user corrects the input
        if ( key === 'country' && value ) setCountryError( false );
        if ( key === 'state' && value ) setStateError( false );
    };

    const handleCountryChange = ( value ) => {
        setUserResponse( ( prevUserResponse ) => ( {
            ...prevUserResponse,
            textResponse: {
                ...prevUserResponse.textResponse,
                country: value,
                state: '',
                city: '',
            },
        } ) );

        FetchStates( value );
        setListOfCities( [] );
    };


    const handleStateChange = ( value ) => {
        setUserResponse( ( prevUserResponse ) => ( {
            ...prevUserResponse,
            textResponse: {
                ...prevUserResponse.textResponse,
                state: value,
                city: '',
            },
        } ) );

        FetchCities( userResponse?.textResponse?.country, value );
    };


    const handleClearAll = () => {
        const clearedTextResponse = {
            houseNumber: '',
            country: '',
            state: '',
            city: '',
            pincode: '',
        };

        setUserResponse( ( prevUserResponse ) => ( {
            ...prevUserResponse,
            textResponse: clearedTextResponse,
        } ) );

        onClearAll();
    };

    const handleNext = () => {
        let hasError = false;

        if ( !userResponse?.textResponse?.country ) {
            setCountryError( true );
            hasError = true;
        }
        // Only validate state if the list of states is not empty
        if ( listOfStates.length > 0 && !userResponse.textResponse.state ) {
            setStateError( true );
            hasError = true;
        }

        if ( !hasError ) {
            onNext();
        }
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div key={ question.id } className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col lg:h-full overflow-y-auto">
                <QuestionAndDescription question={ question } />
                <div className="flex flex-col gap-[0.5rem] mt-2">
                    <div className="flex flex-col gap-1">
                        <p className="text-black font-normal text-lg">Country/Region* { countryError && <p className="text-red-500 text-sm">Please select a country.</p> }</p>
                        <Select
                            showSearch={ true }
                            placeholder="Select Country"
                            onChange={ ( value ) => handleCountryChange( value ) }
                            className={ `address-dropdown mb-3 text-lg lg:w-full sm:w-full ${ countryError ? 'border-red-500' : '' }` }
                            value={ userResponse?.textResponse?.country || undefined }
                        >
                            { listOfCountries.map( ( country, index ) => (
                                <Option key={ index } value={ country }>
                                    { country }
                                </Option>
                            ) ) }
                        </Select>
                    </div>

                    <div className="flex flex-col gap-1">
                        <p className="text-black font-normal text-lg">
                            Pincode
                        </p>
                        <Input
                            placeholder="6 digits [0-9] PIN CODE*"
                            value={ userResponse?.textResponse?.pincode }
                            onChange={ ( e ) => {
                                // Ensure only numeric input and restrict to 6 digits
                                const value = e.target.value;
                                if ( /^\d{0,6}$/.test( value ) ) {
                                    handleAddressChange( 'pincode', value );
                                }
                            } }
                            className="mb-3 p-2 text-lg lg:w-full sm:w-full"
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <p className="text-black font-normal text-lg">Flat / House, Building, Apartment</p>
                        <Input
                            placeholder="1-11-98/170, Hustle Hub, Marathalli"
                            value={ userResponse?.textResponse?.houseNumber }
                            onChange={ ( e ) => handleAddressChange( 'houseNumber', e.target.value ) }
                            className="mb-3 p-2 text-lg lg:w-full sm:w-full"
                        />
                    </div>

                    <div className="flex lg:flex-row sm:flex-col lg:w-full sm:w-full gap-6">
                        <div className="flex flex-col gap-1 w-1/2">
                            <p className="text-black font-normal text-lg">State* { stateError && <p className="text-red-500 text-sm">Please select a state.</p> }
                            </p>
                            <Select
                                showSearch={ true }
                                placeholder="Select State"
                                onChange={ ( value ) => handleStateChange( value ) }
                                disabled={ ( !userResponse?.textResponse?.country ) || ( listOfStates && listOfStates.length === 0 ) } // Disable if no country is selected or no states are available
                                className={ `address-dropdown mb-3 text-lg w-full ${ stateError ? 'border-red-500' : '' }` }
                                value={ userResponse?.textResponse?.state || undefined }
                            >
                                { listOfStates.map( ( state, index ) => (
                                    <Option key={ index } value={ state }>
                                        { state }
                                    </Option>
                                ) ) }
                            </Select>
                        </div>

                        <div className="flex flex-col gap-1 w-1/2">
                            <p className="text-black font-normal text-lg">City</p>
                            <Select
                                showSearch={ true }
                                placeholder="Select City"
                                onChange={ ( value ) => handleAddressChange( 'city', value ) }
                                disabled={ !userResponse?.textResponse?.state || listOfCities.length === 0 } // Disable if no state is selected or no cities are available
                                className="address-dropdown mb-3 text-lg w-full"
                                value={ userResponse?.textResponse?.city || undefined }
                            >
                                { listOfCities.map( ( city, index ) => (
                                    <Option key={ index } value={ city }>
                                        { city }
                                    </Option>
                                ) ) }
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ handleNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext }>
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack }>
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ isAnswerCompleted ? handleNext : null }
                                style={ {
                                    backgroundColor: isAnswerCompleted ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: isAnswerCompleted ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
            {
                countriesLoading || statesLoading || citiesLoading &&
                <div className="overlay">
                    <Spin size="large" />
                </div>
            }
        </div>
    );
};

export default AddressTypeQuestion;