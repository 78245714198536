import React, { useState, useEffect, useRef } from "react";
import { Divider, Input, Select, Space } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";
import {
    dropdownCategory,
    dropdownAction,
} from "../../utils/GoogleAnalytics/events/categories";
import "../../styles.css";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from "react-router-dom";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from "react-redux";
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const { Option } = Select;

const DropDownStepTextSelect = ( {
    question,
    selectedOptionsFromParent,
    onSelect,
    onNext,
    onBack,
    isPagination,
    isQuestionNavigation,
    isValidation,
    onClearAll,
} ) => {
    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedOptionsFromParent?.selectedOptions || [],
        textResponse: selectedOptionsFromParent?.textResponse || '',
    } );

    const [isOptionSelected, setIsOptionSelected] = useState( true );
    const clientConfig = useSelector( ( state ) => state.clientConfiguration );
    const inputRef = useRef( null );

    // useEffect( () => {
    //     if ( selectedOptionsFromParent?.length > 0 ) {
    //         setIsOptionSelected( true );
    //         isValidation( true );
    //     } else {
    //         setIsOptionSelected( false );
    //     }
    // }, [selectedOptionsFromParent] );

    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const numericalDigits = question?.description.match( /\d+/g );
    const isDescriptionIncludesDigit = /\d/.test( question?.description );

    const handleSelect = ( selectedOptions ) => {
        if ( isDescriptionIncludesDigit && selectedOptions.length > numericalDigits ) {
            return;
        }

        const updatedUserResponse = {
            ...userResponse,
            selectedOptions
        };
        setUserResponse( updatedUserResponse );
        onSelect( updatedUserResponse );
        setIsOptionSelected( selectedOptions.length > 0 );
        isValidation( true );
        TrackGoogleAnalyticsEvent( dropdownCategory, dropdownAction );
    };

    const { client_name } = useParams();

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };

    const handleClearAll = () => {
        setUserResponse( { selectedOptions: [], textResponse: '' } );
        setIsOptionSelected( false );
        onClearAll();
    };

    const onNameChange = ( event ) => {
        const newTextResponse = event.target.value;
        const updatedUserResponse = {
            ...userResponse,
            textResponse: newTextResponse
        };
        setUserResponse( updatedUserResponse );
        onSelect( updatedUserResponse );
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    return (
        <div
            className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full"
                }` }
        >
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <Select
                    className="w-full dynamic-dropdown text-lg"
                    value={ Array.isArray( userResponse.selectedOptions ) ? userResponse.selectedOptions : [] }
                    onChange={ handleSelect }
                    showSearch={ true }
                    mode="tags"
                    placeholder={ question?.placeholder_text }
                    filterOption={ ( input, option ) =>
                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                    }
                    onKeyDown={ handleKeyDown }
                    tabIndex={ 0 }
                    dropdownRender={ ( menu ) => (
                        <>
                            { menu }
                            <Divider style={ { margin: '8px 0' } } />
                            <Space className="p-2 w-full">
                                <Input
                                    placeholder="Write your own"
                                    className="w-full p-2"
                                    ref={ inputRef }
                                    value={ userResponse.textResponse }
                                    onChange={ onNameChange }
                                    onKeyDown={ ( e ) => e.stopPropagation() }
                                />
                            </Space>
                        </>
                    ) }
                >
                    { question?.options?.map( ( option ) => (
                        <Option key={ option.id } value={ option.id }>
                            { option.value }
                        </Option>
                    ) ) }
                </Select>
            </div>
            { isPagination ? (
                <></>
            ) : isQuestionNavigation ? (
                <>
                    <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                </>
            ) : (
                <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                    { question?.question_optional === "optional" && (
                        <button className="skip-button" onClick={ onNext }>
                            Skip
                        </button>
                    ) }
                    { perQuestionTimer ? (
                        <></>
                    ) : (
                        <button className="back-button" onClick={ onBack }>
                            Back
                        </button>
                    ) }
                    <button
                        className="next-button"
                        onClick={ isOptionSelected ? onNext : null }
                        style={ {
                            backgroundColor: isOptionSelected
                                ? `${ clientConfig.primary_color }`
                                : `${ clientConfig.primary_color }80`,
                            cursor: isOptionSelected ? "pointer" : "not-allowed",
                        } }
                    >
                        Next
                    </button>
                </div>
            ) }
        </div>
    );
};

export default DropDownStepTextSelect;
