import { useState } from 'react';
import axios from 'axios';
import { AUTH_URL_IN } from '../utils/constants/constants';

const useVerifyOTPEmail = () => {
    const [data, setData] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const [error, setError] = useState( null );

    const verifyOTP = async ( Email, otp ) => {
        setIsLoading( true );
        setError( null );

        try {
            const response = await axios.post( `${ AUTH_URL_IN }/verify-otp`, {
                email: Email,
                otp: otp,
            } );

            setData( response.data );
            setIsLoading( false );
        } catch ( err ) {
            setError( err );
            setIsLoading( false );
        }
    };

    return { data, isLoading, error, verifyOTP };
};

export default useVerifyOTPEmail;
