import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { textTypeCategory, textTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";
import '../../styles.css';

const TextInputStep = ( { question, selectedAnswer, onNext, onBack, isPagination, isQuestionNavigation, isValidation, onClearAll, index } ) => {
    const [isAnswerFilled, setIsAnswerFilled] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedAnswer?.selectedOptions || [],
        textResponse: selectedAnswer?.textResponse || '',
    } );

    useEffect( () => {
        setUserResponse( {
            selectedOptions: selectedAnswer?.selectedOptions || [],
            textResponse: selectedAnswer?.textResponse || '',
        } );
    }, [question] );

    useEffect( () => {
        if ( selectedAnswer?.textResponse ) {
            setIsAnswerFilled( true );
            isValidation( true );
            setUserResponse( {
                selectedOptions: selectedAnswer?.selectedOptions || [],
                textResponse: selectedAnswer?.textResponse || '',
            } );
        } else {
            setIsAnswerFilled( false )
            isValidation( false );
        }
    }, [question] )

    const handleKeyDownName = ( e ) => {
        const regex = /^[A-Za-z\s]+$/;
        if ( !regex.test( e.key ) ) {
            e.preventDefault();
        }
        if ( e.key === "Enter" ) {
            onNext();
        }
        TrackGoogleAnalyticsEvent( textTypeCategory, textTypeAction );
    };

    const handleAnswerChange = ( value ) => {
        const userSelectedAnswer = {
            ...userResponse,
            textResponse: value,
        };
        SaveUserResponseLocalStorage( { question: question, userResponse: userSelectedAnswer, isPagination: isPagination, index: index } )
        setUserResponse( userSelectedAnswer );
        if ( value ) {
            setIsAnswerFilled( true );
            isValidation( true );
        } else {
            setIsAnswerFilled( false );
            isValidation( false );
        }
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleClearAll = () => {
        onClearAll()
    }

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div>
                    <input
                        className="sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                        style={ { resize: "none", textAlign: "left" } }
                        value={ userResponse.textResponse }
                        onChange={ ( e ) =>
                            handleAnswerChange( e.target.value )
                        }
                        onKeyDown={ handleKeyDownName }
                        placeholder={ question?.placeholder_text }
                    />
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ !isAnswerFilled ? null : onNext }
                                style={ {
                                    backgroundColor: !isAnswerFilled ? `${ clientConfig.primary_color }80` : `${ clientConfig.primary_color }`,
                                    cursor: !isAnswerFilled ? 'not-allowed' : 'pointer'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default TextInputStep;
