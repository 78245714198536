import React, { useState, useEffect } from "react";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";

const EmailTypeStep = ( { question, selectedAnswer, onNext, onBack, isPagination, isQuestionNavigation, isValidation, index } ) => {
    const [isAnswerFilled, setIsAnswerFilled] = useState( false );
    const [showError, setShowError] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );
    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedAnswer?.selectedOptions || [],
        textResponse: selectedAnswer?.textResponse || '',
    } );

    const handleAnswerChange = ( value ) => {
        console.log( value );
        const userSelectedAnswer = {
            ...userResponse,
            textResponse: value,
        };

        SaveUserResponseLocalStorage( { question: question, userResponse: userSelectedAnswer, isPagination: isPagination, index: index } )
        setUserResponse( userSelectedAnswer );
        setIsAnswerFilled( true );
    };

    useEffect( () => {
        setUserResponse( {
            selectedOptions: selectedAnswer?.selectedOptions || [],
            textResponse: selectedAnswer?.textResponse || '',
        } );
    }, [question] );

    useEffect( () => {
        if ( selectedAnswer?.textResponse ) {
            setIsAnswerFilled( true )
            setUserResponse( {
                selectedOptions: selectedAnswer?.selectedOptions || [],
                textResponse: selectedAnswer?.textResponse || '',
            } );
        } else {
            setIsAnswerFilled( false )
        }
    }, [question] )

    const isValidEmail = ( value ) => {
        const regex = /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/;
        return regex.test( value );
    };

    useEffect( () => {
        if ( isValidEmail( userResponse.textResponse ) ) {
            isValidation( true )
        } else {
            isValidation( false )
        }
    }, [userResponse] )

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };

    const handleBlur = () => {
        if ( !isValidEmail( userResponse.textResponse ) ) {
            setShowError( true );
        } else {
            setShowError( false );
        }
    };

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div>
                    <input
                        className="w-1/2 sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                        style={ { resize: "none", textAlign: "left", } }
                        value={ userResponse.textResponse }
                        onChange={ ( e ) => {
                            //setEmail( e.target.value );
                            handleAnswerChange( e.target.value );
                        } }
                        placeholder={ question?.placeholder_text }
                        onKeyDown={ handleKeyDown }
                        onBlur={ handleBlur }
                    />
                    {
                        showError &&
                        <p className="text-red-500 text-xs left-0 mt-2">
                            Invalid email format. Please enter a valid email.
                        </p>
                    }
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="skip-button" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack } >
                                        Back
                                    </button>

                            }
                            <button
                                className="next-button"
                                onClick={ () => {
                                    if ( isValidEmail( userResponse.textResponse ) ) {
                                        onNext();
                                    }
                                } }
                                style={ {
                                    backgroundColor: !isValidEmail( userResponse.textResponse ) ? `${ clientConfig.primary_color }80` : `${ clientConfig.primary_color }`,
                                    cursor: !isValidEmail( userResponse.textResponse ) ? 'not-allowed' : 'pointer'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default EmailTypeStep;
