export const indianStates = [
    'Andaman and Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam',
    'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra and Nagar Haveli', 'Daman and Diu',
    'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu', 'Jharkhand',
    'Karnataka', 'Kashmir', 'Kerala', 'Ladakh', 'Lakshadweep', 'Madhya Pradesh',
    'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Puducherry',
    'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttarakhand',
    'Uttar Pradesh', 'West Bengal',
];

export const indianCities = [
    "Bamboo Flat",
    "Nicobar",
    "Port Blair",
    "South Andaman",
    "Addanki",
    "Adoni",
    "Akasahebpet",
    "Akividu",
    "Akkarampalle",
    "Amalapuram",
    "Amudalavalasa",
    "Anakapalle",
    "Anantapur",
    "Atmakur",
    "Attili",
    "Avanigadda",
    "Badvel",
    "Banganapalle",
    "Bapatla",
    "Betamcherla",
    "Bhattiprolu",
    "Bhimavaram",
    "Bhimunipatnam",
    "Bobbili",
    "Challapalle",
    "Chemmumiahpet",
    "Chilakalurupet",
    "Chinnachowk",
    "Chipurupalle",
    "Chirala",
    "Chittoor",
    "Chodavaram",
    "Cuddapah",
    "Cumbum",
    "Darsi",
    "Dharmavaram",
    "Dhone",
    "Diguvametta",
    "East Godavari",
    "Elamanchili",
    "Ellore",
    "Emmiganur",
    "Erraguntla",
    "Etikoppaka",
    "Gajuwaka",
    "Ganguvada",
    "Gannavaram",
    "Giddalur",
    "Gokavaram",
    "Gorantla",
    "Govindapuram,Chilakaluripet,Guntur",
    "Gudivada",
    "Gudlavalleru",
    "Gudur",
    "Guntakal Junction",
    "Guntur",
    "Hindupur",
    "Ichchapuram",
    "Jaggayyapeta",
    "Jammalamadugu",
    "Kadiri",
    "Kaikalur",
    "Kakinada",
    "Kalyandurg",
    "Kamalapuram",
    "Kandukur",
    "Kanigiri",
    "Kankipadu",
    "Kanuru",
    "Kavali",
    "Kolanukonda",
    "Kondapalle",
    "Korukollu",
    "Kosigi",
    "Kovvur",
    "Krishna",
    "Kuppam",
    "Kurnool",
    "Macherla",
    "Machilipatnam",
    "Madanapalle",
    "Madugula",
    "Mandapeta",
    "Mandasa",
    "Mangalagiri",
    "Markapur",
    "Nagari",
    "Nagireddipalli",
    "Nandigama",
    "Nandikotkur",
    "Nandyal",
    "Narasannapeta",
    "Narasapur",
    "Narasaraopet",
    "Narasingapuram",
    "Narayanavanam",
    "Narsipatnam",
    "Nayudupet",
    "Nellore",
    "Nidadavole",
    "Nuzvid",
    "Ongole",
    "Pakala",
    "Palakollu",
    "Palasa",
    "Palkonda",
    "Pallevada",
    "Palmaner",
    "Parlakimidi",
    "Parvatipuram",
    "Pavuluru",
    "Pedana",
    "pedda nakkalapalem",
    "Peddapuram",
    "Penugonda",
    "Penukonda",
    "Phirangipuram",
    "Pippara",
    "Pithapuram",
    "Polavaram",
    "Ponnur",
    "Ponnuru",
    "Prakasam",
    "Proddatur",
    "Pulivendla",
    "Punganuru",
    "Puttaparthi",
    "Puttur",
    "Rajahmundry",
    "Ramachandrapuram",
    "Ramanayyapeta",
    "Ramapuram",
    "Rampachodavaram",
    "Rayachoti",
    "Rayadrug",
    "Razam",
    "Razampeta",
    "Razole",
    "Renigunta",
    "Repalle",
    "Salur",
    "Samalkot",
    "Sattenapalle",
    "Singarayakonda",
    "Sompeta",
    "Srikakulam",
    "Srisailain",
    "Suluru",
    "Tadepalle",
    "Tadepallegudem",
    "Tadpatri",
    "Tanuku",
    "Tekkali",
    "Tirumala",
    "Tirupati",
    "Tuni",
    "Uravakonda",
    "vadlamuru",
    "Vadlapudi",
    "Venkatagiri",
    "Vepagunta",
    "Vetapalem",
    "Vijayawada",
    "Vinukonda",
    "Visakhapatnam",
    "Vizianagaram",
    "Vizianagaram District",
    "Vuyyuru",
    "West Godavari",
    "Yanam",
    "Yanamalakuduru",
    "Yarada",
    "Along",
    "Anjaw",
    "Basar",
    "Bomdila",
    "Changlang",
    "Dibang Valley",
    "East Kameng",
    "East Siang",
    "Hayuliang",
    "Itanagar",
    "Khonsa",
    "Kurung Kumey",
    "Lohit District",
    "Lower Dibang Valley",
    "Lower Subansiri",
    "Margherita",
    "Naharlagun",
    "Pasighat",
    "Tawang",
    "Tezu",
    "Tirap",
    "Upper Siang",
    "Upper Subansiri",
    "West Kameng",
    "West Siang",
    "Ziro",
    "Abhayapuri",
    "Amguri",
    "Badarpur",
    "Baksa",
    "Barpathar",
    "Barpeta",
    "Barpeta Road",
    "Basugaon",
    "Bihpuriagaon",
    "Bijni",
    "Bilasipara",
    "Bokajan",
    "Bokakhat",
    "Bongaigaon",
    "Cachar",
    "Chabua",
    "Chapar",
    "Chirang",
    "Darrang",
    "Dergaon",
    "Dhekiajuli",
    "Dhemaji",
    "Dhing",
    "Dhubri",
    "Dibrugarh",
    "Digboi",
    "Dima Hasao District",
    "Diphu",
    "Dispur",
    "Duliagaon",
    "Dum Duma",
    "Gauripur",
    "Goalpara",
    "Gohpur",
    "Golaghat",
    "Golakganj",
    "Goshaingaon",
    "Guwahati",
    "Haflong",
    "Hailakandi",
    "Hajo",
    "Hojai",
    "Howli",
    "Jogighopa",
    "Jorhat",
    "Kamrup",
    "Kamrup Metropolitan",
    "Karbi Anglong",
    "Karimganj",
    "Kharupatia",
    "Kokrajhar",
    "Lakhimpur",
    "Lakhipur",
    "Lala",
    "Lumding Railway Colony",
    "Mahur",
    "Maibong",
    "Makum",
    "Mangaldai",
    "Mariani",
    "Moranha",
    "Morigaon",
    "Nagaon",
    "Nahorkatiya",
    "Nalbari",
    "Namrup",
    "Nazira",
    "North Guwahati",
    "North Lakhimpur",
    "Numaligarh",
    "Palasbari",
    "Raha",
    "Rangapara",
    "Rangia",
    "Sapatgram",
    "Sarupathar",
    "Sibsagar",
    "Silapathar",
    "Silchar",
    "Soalkuchi",
    "Sonari",
    "Sonitpur",
    "Sorbhog",
    "Tezpur",
    "Tinsukia",
    "Titabar",
    "Udalguri",
    "Amarpur",
    "Araria",
    "Arrah",
    "Arwal",
    "Asarganj",
    "Aurangabad",
    "Bagaha",
    "Bahadurganj",
    "Bairagnia",
    "Baisi",
    "Bakhtiyarpur",
    "Bangaon",
    "Banka",
    "Banmankhi",
    "Bar Bigha",
    "Barauli",
    "Barh",
    "Barhiya",
    "Bariarpur",
    "Baruni",
    "Begusarai",
    "Belsand",
    "Bettiah",
    "Bhabhua",
    "Bhagalpur",
    "Bhagirathpur",
    "Bhawanipur",
    "Bhojpur",
    "Bihar Sharif",
    "Bihariganj",
    "Bikramganj",
    "Birpur",
    "Buddh Gaya",
    "Buxar",
    "Chakia",
    "Chapra",
    "Chhatapur",
    "Colgong",
    "Dalsingh Sarai",
    "Darbhanga",
    "Daudnagar",
    "Dehri",
    "Dhaka",
    "Dighwara",
    "Dinapore",
    "Dumra",
    "Dumraon",
    "Fatwa",
    "Forbesganj",
    "Gaya",
    "Ghoga",
    "Gopalganj",
    "Hajipur",
    "Hilsa",
    "Hisua",
    "Islampur",
    "Jagdispur",
    "Jahanabad",
    "Jamalpur",
    "Jamui",
    "Jaynagar",
    "Jehanabad",
    "Jha-Jha",
    "Jhanjharpur",
    "Jogbani",
    "Kaimur District",
    "Kasba",
    "Katihar",
    "Khagaria",
    "Khagaul",
    "Kharagpur",
    "Khusropur",
    "Kishanganj",
    "Koath",
    "Koelwar",
    "Lakhisarai",
    "Lalganj",
    "Luckeesarai",
    "Madhepura",
    "Madhipura",
    "Madhubani",
    "Maharajgani",
    "Mairwa",
    "Maner",
    "Manihari",
    "Marhaura",
    "Masaurhi Buzurg",
    "Mohiuddinnagar",
    "Mokameh",
    "Monghyr",
    "Mothihari",
    "Munger",
    "Murliganj",
    "Muzaffarpur",
    "Nabinagar",
    "Nalanda",
    "Nasriganj",
    "Naugachhia",
    "Nawada",
    "Nirmali",
    "Pashchim Champaran",
    "Patna",
    "Piro",
    "Pupri",
    "Purba Champaran",
    "Purnia",
    "Rafiganj",
    "Raghunathpur",
    "Rajgir",
    "Ramnagar",
    "Raxaul",
    "Revelganj",
    "Rohtas",
    "Rusera",
    "Sagauli",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Shahbazpur",
    "Shahpur",
    "Sheikhpura",
    "Sheohar",
    "Sherghati",
    "Silao",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Teghra",
    "Tekari",
    "Thakurganj",
    "Vaishali",
    "Waris Aliganj",
    "Chandigarh",
    "Akaltara",
    "Ambagarh Chauki",
    "Ambikapur",
    "Arang",
    "Baikunthpur",
    "Balod",
    "Baloda",
    "Baloda Bazar",
    "Basna",
    "Bastar",
    "Bemetara",
    "Bhanpuri",
    "Bhatapara",
    "Bhatgaon",
    "Bhilai",
    "Bijapur",
    "Bilaspur",
    "Champa",
    "Chhuikhadan",
    "Deori",
    "Dhamtari",
    "Dongargaon",
    "Dongargarh",
    "Durg",
    "Gandai",
    "Gariaband",
    "Gaurela",
    "Gharghoda",
    "Gidam",
    "Jagdalpur",
    "Janjgir",
    "Janjgir-Champa",
    "Jashpur",
    "Jashpurnagar",
    "Junagarh",
    "Kabeerdham",
    "Kanker",
    "Katghora",
    "Kawardha",
    "Khairagarh",
    "Khamharia",
    "Kharod",
    "Kharsia",
    "Kirandul",
    "Kondagaon",
    "Korba",
    "Koriya",
    "Kota",
    "Kotaparh",
    "Kumhari",
    "Kurud",
    "Lormi",
    "Mahasamund",
    "Mungeli",
    "Narayanpur",
    "Narharpur",
    "Pandaria",
    "Pandatarai",
    "Pasan",
    "Patan",
    "Pathalgaon",
    "Pendra",
    "Pithora",
    "Raigarh",
    "Raipur",
    "Raj Nandgaon",
    "Raj-Nandgaon",
    "Ramanuj Ganj",
    "Ratanpur",
    "Sakti",
    "Saraipali",
    "Sarangarh",
    "Seorinarayan",
    "Simga",
    "Surguja",
    "Takhatpur",
    "Umarkot",
    "Uttar Bastar Kanker",
    "Amli",
    "Dadra",
    "Dadra & Nagar Haveli",
    "Daman",
    "Daman District",
    "Diu",
    "Silvassa",
    "Alipur",
    "Bawana",
    "Central Delhi",
    "Delhi",
    "Deoli",
    "East Delhi",
    "Karol Bagh",
    "Najafgarh",
    "Nangloi Jat",
    "Narela",
    "New Delhi",
    "North Delhi",
    "North East Delhi",
    "North West Delhi",
    "Pitampura",
    "Rohini",
    "South Delhi",
    "South West Delhi",
    "West Delhi",
    "Aldona",
    "Arambol",
    "Baga",
    "Bambolim",
    "Bandora",
    "Benaulim",
    "Calangute",
    "Candolim",
    "Carapur",
    "Cavelossim",
    "Chicalim",
    "Chinchinim",
    "Colovale",
    "Colva",
    "Cortalim",
    "Cuncolim",
    "Curchorem",
    "Curti",
    "Davorlim",
    "Dicholi",
    "Goa Velha",
    "Guirim",
    "Jua",
    "Kankon",
    "Madgaon",
    "Mapuca",
    "Morjim",
    "Mormugao",
    "Navelim",
    "North Goa",
    "Palle",
    "Panaji",
    "Pernem",
    "Ponda",
    "Quepem",
    "Queula",
    "Raia",
    "Saligao",
    "Sancoale",
    "Sanguem",
    "Sanquelim",
    "Sanvordem",
    "Serula",
    "Solim",
    "South Goa",
    "Taleigao",
    "Vagator",
    "Valpoy",
    "Varca",
    "Vasco da Gama",
    "Abrama",
    "Adalaj",
    "Ahmedabad",
    "Ahwa",
    "Amod",
    "Amreli",
    "Amroli",
    "Anand",
    "Anjar",
    "Ankleshwar",
    "Babra",
    "Bagasara",
    "Bagasra",
    "Banas Kantha",
    "Bantva",
    "Bardoli",
    "Bedi",
    "Bhachau",
    "Bhanvad",
    "Bharuch",
    "Bhavnagar",
    "Bhayavadar",
    "Bhuj",
    "Bilimora",
    "Bilkha",
    "Borsad",
    "Botad",
    "Chaklasi",
    "Chalala",
    "Chanasma",
    "Chhala",
    "Chhota Udepur",
    "Chikhli",
    "Chotila",
    "Dabhoi",
    "Dahegam",
    "Dahod",
    "Dakor",
    "Damnagar",
    "Dangs (India)",
    "Dayapar",
    "Delvada",
    "Delwada",
    "Devbhumi Dwarka",
    "Devgadh Bariya",
    "Dhandhuka",
    "Dhanera",
    "Dharampur",
    "Dhari",
    "Dhola",
    "Dholka",
    "Dhoraji",
    "Dhrangadhra",
    "Dhrol",
    "Dhuwaran",
    "Disa",
    "Dohad",
    "Dungarpur",
    "Dwarka",
    "Gadhada",
    "Gandevi",
    "Gandhidham",
    "Gandhinagar",
    "Gariadhar",
    "Ghogha",
    "Gir Somnath",
    "Godhra",
    "Gondal",
    "Halol",
    "Halvad",
    "Hansot",
    "Harij",
    "Himatnagar",
    "Jalalpore",
    "Jalalpur",
    "Jambusar",
    "Jamnagar",
    "Jasdan",
    "Jetalsar",
    "Jetpur",
    "Jhulasan",
    "Jodhpur",
    "Jodia",
    "Jodiya Bandar",
    "Junagadh",
    "Kachchh",
    "Kadi",
    "Kadod",
    "Kalavad",
    "Kalol",
    "Kandla",
    "Kanodar",
    "Kapadvanj",
    "Karamsad",
    "Kathor",
    "Katpur",
    "Kavant",
    "Kawant",
    "Keshod",
    "Khambhalia",
    "Khambhat",
    "Kheda",
    "Khedbrahma",
    "Kheralu",
    "Kodinar",
    "Kosamba",
    "Kundla",
    "Kutch district",
    "Kutiyana",
    "Lakhtar",
    "Lalpur",
    "Lathi",
    "Limbdi",
    "Lunavada",
    "Mahemdavad",
    "Mahesana",
    "Mahudha",
    "Malpur",
    "Manavadar",
    "Mandal",
    "Mandvi",
    "Mandvi (Surat)",
    "Mangrol",
    "Mansa",
    "Meghraj",
    "Mehsana",
    "Mendarda",
    "Modasa",
    "Morbi",
    "Morva (Hadaf)",
    "Morwa",
    "Mundra",
    "Nadiad",
    "Naliya",
    "Narmada",
    "Naroda",
    "Navsari",
    "Okha",
    "Olpad",
    "Paddhari",
    "Padra",
    "Palanpur",
    "Palitana",
    "Paliyad",
    "Panch Mahals",
    "Panchmahal district",
    "Pardi",
    "Parnera",
    "Patan",
    "Pavi Jetpur",
    "Petlad",
    "Porbandar",
    "Radhanpur",
    "Rajkot",
    "Rajpipla",
    "Rajula",
    "Ranavav",
    "Rapar",
    "Roha",
    "Sabar Kantha",
    "Sachin",
    "Salaya",
    "Sanand",
    "Sankheda",
    "Sarkhej",
    "Savarkundla",
    "Sayla",
    "Shahpur",
    "Shivrajpur",
    "Siddhpur",
    "Sihor",
    "Sikka",
    "Sinor",
    "Sojitra",
    "Songadh",
    "Surat",
    "Surendranagar",
    "Talaja",
    "Tankara",
    "Tapi",
    "Than",
    "Thangadh",
    "Tharad",
    "Thasra",
    "The Dangs",
    "Umrala",
    "Umreth",
    "Un",
    "Una",
    "Unjha",
    "Upleta",
    "Utran",
    "Vadnagar",
    "Vadodara",
    "Vaghodia",
    "Vallabh Vidyanagar",
    "Vallabhipur",
    "Valsad",
    "Vansda",
    "Vapi",
    "Vartej",
    "Vasa",
    "Vaso",
    "Vejalpur",
    "Veraval",
    "Vijapur",
    "Vinchhiya",
    "Vinchia",
    "Virpur",
    "Visavadar",
    "Visnagar",
    "Vyara",
    "Wadhai",
    "Wadhwan",
    "Waghai",
    "Wankaner",
    "Ambala",
    "Asandh",
    "Ateli Mandi",
    "Bahadurgarh",
    "Bara Uchana",
    "Barwala",
    "Bawal",
    "Beri Khas",
    "Bhiwani",
    "Bilaspur",
    "Buriya",
    "Charkhi Dadri",
    "Chhachhrauli",
    "Dabwali",
    "Dharuhera",
    "Ellenabad",
    "Faridabad",
    "Faridabad District",
    "Farrukhnagar",
    "Fatehabad",
    "Fatehabad District",
    "Firozpur Jhirka",
    "Gharaunda",
    "Gohana",
    "Gorakhpur",
    "Gurgaon",
    "Hansi",
    "Hasanpur",
    "Hisar",
    "Hodal",
    "Inda Chhoi",
    "Indri",
    "Jagadhri",
    "Jakhal",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Kalanaur",
    "Kalanwali",
    "Kanina Khas",
    "Karnal",
    "Kharkhauda",
    "Kheri Sampla",
    "Kurukshetra",
    "Ladwa",
    "Loharu",
    "Maham",
    "Mahendragarh",
    "Mandholi Kalan",
    "Mustafabad",
    "Narayangarh",
    "Narnaul",
    "Narnaund",
    "Narwana",
    "Nilokheri",
    "Nuh",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Pataudi",
    "Pehowa",
    "Pinjaur",
    "Punahana",
    "Pundri",
    "Radaur",
    "Rania",
    "Ratia",
    "Rewari",
    "Rewari District",
    "Rohtak",
    "Safidon",
    "Samalkha",
    "Shadipur Julana",
    "Shahabad",
    "Sirsa",
    "Sohna",
    "Sonipat",
    "Taoru",
    "Thanesar",
    "Tohana",
    "Tosham",
    "Uklana",
    "Yamunanagar",
    "Arki",
    "Baddi",
    "Banjar",
    "Bilaspur",
    "Chamba",
    "Chaupal",
    "Chowari",
    "Chuari Khas",
    "Dagshai",
    "Dalhousie",
    "Daulatpur",
    "Dera Gopipur",
    "Dharamsala",
    "Gagret",
    "Ghumarwin",
    "Hamirpur",
    "Jawala Mukhi",
    "Jogindarnagar",
    "Jubbal",
    "Jutogh",
    "Kalka",
    "Kangar",
    "Kangra",
    "Kasauli",
    "Kinnaur",
    "Kotkhai",
    "Kotla",
    "Kulu",
    "Kyelang",
    "Lahul and Spiti",
    "Manali",
    "Mandi",
    "Nadaun",
    "Nagar",
    "Nagrota",
    "Nahan",
    "Nalagarh",
    "Palampur",
    "Pandoh",
    "Paonta Sahib",
    "Parwanoo",
    "Rajgarh",
    "Rampur",
    "Rohru",
    "Sabathu",
    "Santokhgarh",
    "Sarahan",
    "Sarka Ghat",
    "Seoni",
    "Shimla",
    "Sirmaur",
    "Solan",
    "Sundarnagar",
    "Theog",
    "Tira Sujanpur",
    "Una",
    "Yol",
    "Akhnur",
    "Anantnag",
    "Awantipur",
    "Badgam",
    "Bandipore",
    "Bandipura",
    "Banihal",
    "Baramula",
    "Batoti",
    "Bhadarwah",
    "Bijbehara",
    "Bishnah",
    "Doda",
    "Gandarbal",
    "Ganderbal",
    "Gho Brahmanan de",
    "Hajan",
    "Hiranagar",
    "Jammu",
    "Jaurian",
    "Kathua",
    "Katra",
    "Khaur",
    "Kishtwar",
    "Kud",
    "Kulgam",
    "Kupwara",
    "Ladakh",
    "Magam",
    "Nawanshahr",
    "Noria",
    "Padam",
    "Pahlgam",
    "Parol",
    "Pattan",
    "Pulwama",
    "Punch",
    "Qazigund",
    "Rajaori",
    "Rajauri",
    "Ramban",
    "Ramgarh",
    "Ramnagar",
    "Riasi",
    "Samba",
    "Shupiyan",
    "Sopur",
    "Soyibug",
    "Srinagar",
    "Sumbal",
    "Thang",
    "Thanna Mandi",
    "Tral",
    "Tsrar Sharif",
    "Udhampur",
    "Uri",
    "Bagra",
    "Barka Kana",
    "Barki Saria",
    "Barwadih",
    "Bhojudih",
    "Bokaro",
    "Bundu",
    "Chaibasa",
    "Chakradharpur",
    "Chakulia",
    "Chandil",
    "Chas",
    "Chatra",
    "Chiria",
    "Daltonganj",
    "Deogarh",
    "Dhanbad",
    "Dhanwar",
    "Dugda",
    "Dumka",
    "Garhwa",
    "Ghatsila",
    "Giridih",
    "Gobindpur",
    "Godda",
    "Gomoh",
    "Gopinathpur",
    "Gua",
    "Gumia",
    "Gumla",
    "Hazaribag",
    "Hazaribagh",
    "Hesla",
    "Husainabad",
    "Jagannathpur",
    "Jamadoba",
    "Jamshedpur",
    "Jamtara",
    "Jasidih",
    "Jharia",
    "Jugsalai",
    "Jumri Tilaiya",
    "Kalikapur",
    "Kandra",
    "Kanke",
    "Katras",
    "Kenduadih",
    "Kharsawan",
    "Khunti",
    "Kodarma",
    "Kuju",
    "Latehar",
    "Lohardaga",
    "Madhupur",
    "Malkera",
    "Manoharpur",
    "Mugma",
    "Mushabani",
    "Neturhat",
    "Nirsa",
    "Noamundi",
    "Pakur",
    "Palamu",
    "Pashchim Singhbhum",
    "patamda",
    "Pathardih",
    "Purba Singhbhum",
    "Ramgarh",
    "Ranchi",
    "Ray",
    "Sahibganj",
    "Saraikela",
    "Sarubera",
    "Sijua",
    "Simdega",
    "Sini",
    "Topchanchi",
    "Afzalpur",
    "Ajjampur",
    "Aland",
    "Alnavar",
    "Alur",
    "Anekal",
    "Ankola",
    "Annigeri",
    "Arkalgud",
    "Arsikere",
    "Athni",
    "Aurad",
    "Badami",
    "Bagalkot",
    "Bagepalli",
    "Bail-Hongal",
    "Ballari",
    "Banavar",
    "Bangalore Rural",
    "Bangalore Urban",
    "Bangarapet",
    "Bannur",
    "Bantval",
    "Basavakalyan",
    "Basavana Bagevadi",
    "Belgaum",
    "Bellary",
    "Belluru",
    "Beltangadi",
    "Belur",
    "Bengaluru",
    "Bhadravati",
    "Bhalki",
    "Bhatkal",
    "Bidar",
    "Bijapur",
    "Bilgi",
    "Birur",
    "Byadgi",
    "Byndoor",
    "Canacona",
    "Challakere",
    "Chamrajnagar",
    "Channagiri",
    "Channapatna",
    "Channarayapatna",
    "Chik Ballapur",
    "Chikkaballapur",
    "Chikmagalur",
    "Chiknayakanhalli",
    "Chikodi",
    "Chincholi",
    "Chintamani",
    "Chitapur",
    "Chitradurga",
    "Closepet",
    "Coondapoor",
    "Dakshina Kannada",
    "Dandeli",
    "Davanagere",
    "Devanhalli",
    "Dharwad",
    "Dod Ballapur",
    "French Rocks",
    "Gadag",
    "Gadag-Betageri",
    "Gajendragarh",
    "Gangawati",
    "Gangolli",
    "Gokak",
    "Gokarna",
    "Goribidnur",
    "Gorur",
    "Gubbi",
    "Gudibanda",
    "Gulbarga",
    "Guledagudda",
    "Gundlupēt",
    "Gurmatkal",
    "Hadagalli",
    "Haliyal",
    "Hampi",
    "Hangal",
    "Harihar",
    "Harpanahalli",
    "Hassan",
    "Haveri",
    "Heggadadevankote",
    "Hirekerur",
    "Hiriyur",
    "Holalkere",
    "Hole Narsipur",
    "Homnabad",
    "Honavar",
    "Honnali",
    "Hosanagara",
    "Hosangadi",
    "Hosdurga",
    "Hoskote",
    "Hospet",
    "Hubli",
    "Hukeri",
    "Hungund",
    "Hunsur",
    "Ilkal",
    "Indi",
    "Jagalur",
    "Jamkhandi",
    "Jevargi",
    "Kadur",
    "Kalghatgi",
    "Kampli",
    "Kankanhalli",
    "Karkala",
    "Karwar",
    "Kavalur",
    "Kerur",
    "Khanapur",
    "Kodagu",
    "Kodigenahalli",
    "Kodlipet",
    "Kolar",
    "Kollegal",
    "Konanur",
    "Konnur",
    "Koppa",
    "Koppal",
    "Koratagere",
    "Kotturu",
    "Krishnarajpet",
    "Kudachi",
    "Kudligi",
    "Kumsi",
    "Kumta",
    "Kundgol",
    "Kunigal",
    "Kurgunta",
    "Kushalnagar",
    "Kushtagi",
    "Lakshmeshwar",
    "Lingsugur",
    "Londa",
    "Maddagiri",
    "Maddur",
    "Madikeri",
    "Magadi",
    "Mahalingpur",
    "Malavalli",
    "Malpe",
    "Malur",
    "Mandya",
    "Mangalore",
    "Manipal",
    "Manvi",
    "Mayakonda",
    "Melukote",
    "Mudbidri",
    "Muddebihal",
    "Mudgal",
    "Mudgere",
    "Mudhol",
    "Mulbagal",
    "Mulgund",
    "Mulki",
    "Mundargi",
    "Mundgod",
    "Munirabad",
    "Murudeshwara",
    "Mysore",
    "Nagamangala",
    "Nanjangud",
    "Narasimharajapura",
    "Naregal",
    "Nargund",
    "Navalgund",
    "Nelamangala",
    "Nyamti",
    "Pangala",
    "Pavugada",
    "Piriyapatna",
    "Ponnampet",
    "Puttur",
    "Rabkavi",
    "Raichur",
    "Ramanagara",
    "Ranibennur",
    "Raybag",
    "Robertsonpet",
    "Ron",
    "Sadalgi",
    "Sagar",
    "Sakleshpur",
    "Sandur",
    "Sanivarsante",
    "Sankeshwar",
    "Sargur",
    "Saundatti",
    "Savanur",
    "Seram",
    "Shahabad",
    "Shahpur",
    "Shiggaon",
    "Shikarpur",
    "Shimoga",
    "Shirhatti",
    "Shorapur",
    "Shrirangapattana",
    "Siddapur",
    "Sidlaghatta",
    "Sindgi",
    "Sindhnur",
    "Sira",
    "Sirsi",
    "Siruguppa",
    "Someshwar",
    "Somvarpet",
    "Sorab",
    "Sravana Belgola",
    "Sringeri",
    "Srinivaspur",
    "Sulya",
    "Suntikoppa",
    "Talikota",
    "Tarikere",
    "Tekkalakote",
    "Terdal",
    "Tiptur",
    "Tirthahalli",
    "Tirumakudal Narsipur",
    "Tumkur",
    "Turuvekere",
    "Udupi",
    "Ullal",
    "Uttar Kannada",
    "Vadigenhalli",
    "Virarajendrapet",
    "Wadi",
    "Yadgir",
    "Yelahanka",
    "Yelandur",
    "Yelbarga",
    "Yellapur",
    "Adur",
    "Alappuzha",
    "Aluva",
    "Alwaye",
    "Angamali",
    "Aroor",
    "Arukutti",
    "Attingal",
    "Avanoor",
    "Azhikkal",
    "Badagara",
    "Beypore",
    "Changanacheri",
    "Chēlakara",
    "Chengannur",
    "Cherpulassery",
    "Cherthala",
    "Chetwayi",
    "Chittur",
    "Cochin",
    "Dharmadam",
    "Edakkulam",
    "Elur",
    "Erattupetta",
    "Ernakulam",
    "Ferokh",
    "Guruvayur",
    "Idukki",
    "Iringal",
    "Irinjalakuda",
    "Kadakkavoor",
    "Kalamassery",
    "Kalavoor",
    "Kalpatta",
    "Kannangad",
    "Kannavam",
    "Kannur",
    "Kasaragod",
    "Kasaragod District",
    "Kattanam",
    "Kayankulam",
    "Kizhake Chalakudi",
    "Kodungallur",
    "Kollam",
    "Kotamangalam",
    "Kottayam",
    "Kovalam",
    "Kozhikode",
    "Kumbalam",
    "Kunnamangalam",
    "Kunnamkulam",
    "Kunnumma",
    "Kutiatodu",
    "Kuttampuzha",
    "Lalam",
    "Mahē",
    "Malappuram",
    "Manjeri",
    "Manjēshvar",
    "Mannarakkat",
    "Marayur",
    "Mattanur",
    "Mavelikara",
    "Mavoor",
    "Muluppilagadu",
    "Munnar",
    "Muvattupula",
    "Muvattupuzha",
    "Nadapuram",
    "Naduvannur",
    "Nedumangad",
    "Neyyattinkara",
    "Nilēshwar",
    "Ottappalam",
    "Palackattumala",
    "Palakkad district",
    "Palghat",
    "Panamaram",
    "Pappinisshēri",
    "Paravur Tekkumbhagam",
    "Pariyapuram",
    "Pathanamthitta",
    "Pattanamtitta",
    "Payyannur",
    "Perumbavoor",
    "Perumpavur",
    "Perya",
    "Piravam",
    "Ponmana",
    "Ponnani",
    "Punalur",
    "Ramamangalam",
    "Shertallai",
    "Shōranur",
    "Talipparamba",
    "Tellicherry",
    "Thanniyam",
    "Thiruvananthapuram",
    "Thrissur",
    "Thrissur District",
    "Tirur",
    "Tiruvalla",
    "Vaikam",
    "Varkala",
    "Vayalar",
    "Vettur",
    "Wayanad",
    "Kargil",
    "Leh",
    "Kavaratti",
    "Lakshadweep",
    "Agar",
    "Ajaigarh",
    "Akodia",
    "Alampur",
    "Alirajpur",
    "Alot",
    "Amanganj",
    "Amarkantak",
    "Amarpatan",
    "Amarwara",
    "Ambah",
    "Amla",
    "Anjad",
    "Antri",
    "Anuppur",
    "Aron",
    "Ashoknagar",
    "Ashta",
    "Babai",
    "Badarwas",
    "Badnawar",
    "Bag",
    "Bagli",
    "Baihar",
    "Baikunthpur",
    "Bakshwaho",
    "Balaghat",
    "Baldeogarh",
    "Bamna",
    "Bamor Kalan",
    "Bamora",
    "Banda",
    "Barela",
    "Barghat",
    "Bargi",
    "Barhi",
    "Barwani",
    "Basoda",
    "Begamganj",
    "Beohari",
    "Berasia",
    "Betma",
    "Betul",
    "Betul Bazar",
    "Bhabhra",
    "Bhainsdehi",
    "Bhander",
    "Bhanpura",
    "Bhawaniganj",
    "Bhikangaon",
    "Bhind",
    "Bhitarwar",
    "Bhopal",
    "Biaora",
    "Bijawar",
    "Bijrauni",
    "Bodri",
    "Burhanpur",
    "Burhar",
    "Chanderi",
    "Chandia",
    "Chandla",
    "Chhatarpur",
    "Chhindwara",
    "Chichli",
    "Chorhat",
    "Daboh",
    "Dabra",
    "Damoh",
    "Datia",
    "Deori Khas",
    "Depalpur",
    "Dewas",
    "Dhamnod",
    "Dhana",
    "Dhar",
    "Dharampuri",
    "Dindori",
    "Etawa",
    "Gadarwara",
    "Garha Brahman",
    "Garhakota",
    "Gautampura",
    "Ghansor",
    "Gogapur",
    "Gohadi",
    "Govindgarh",
    "Guna",
    "Gurh",
    "Gwalior",
    "Harda",
    "Harda Khas",
    "Harpalpur",
    "Harrai",
    "Harsud",
    "Hatod",
    "Hatta",
    "Hindoria",
    "Hoshangabad",
    "Iawar",
    "Ichhawar",
    "Iklehra",
    "Indore",
    "Isagarh",
    "Itarsi",
    "Jabalpur",
    "Jaisinghnagar",
    "Jaithari",
    "Jamai",
    "Jaora",
    "Jatara",
    "Jawad",
    "Jhabua",
    "Jiran",
    "Jobat",
    "Kailaras",
    "Kaimori",
    "Kannod",
    "Kareli",
    "Karera",
    "Karrapur",
    "Kasrawad",
    "Katangi",
    "Katni",
    "Khachrod",
    "Khailar",
    "Khajuraho Group of Monuments",
    "Khamaria",
    "Khandwa",
    "Khandwa district",
    "Khargapur",
    "Khargone",
    "Khategaon",
    "Khilchipur",
    "Khirkiyan",
    "Khujner",
    "Khurai",
    "Kolaras",
    "Korwai",
    "Kotar",
    "Kothi",
    "Kotma",
    "Kotwa",
    "Kukshi",
    "Kumbhraj",
    "Lahar",
    "Lakhnadon",
    "Leteri",
    "Lodhikheda",
    "Machalpur",
    "Madhogarh",
    "Maheshwar",
    "Mahgawan",
    "Maihar",
    "Majholi",
    "Maksi",
    "Malhargarh",
    "Manasa",
    "Manawar",
    "Mandideep",
    "Mandla",
    "Mandleshwar",
    "Mandsaur",
    "Mangawan",
    "Manpur",
    "Mau",
    "Mauganj",
    "Mihona",
    "Mohgaon",
    "Morar",
    "Morena",
    "Multai",
    "Mundi",
    "Mungaoli",
    "Murwara",
    "Nagda",
    "Nagod",
    "Naigarhi",
    "Nainpur",
    "Namli",
    "Naraini",
    "Narayangarh",
    "Narsimhapur",
    "Narsinghgarh",
    "Narwar",
    "Nasrullahganj",
    "Neemuch",
    "Nepanagar",
    "Orchha",
    "Pachmarhi",
    "Palera",
    "Pali",
    "Panagar",
    "Panara",
    "Pandhana",
    "Pandhurna",
    "Panna",
    "Pansemal",
    "Parasia",
    "Patan",
    "Patharia",
    "Pawai",
    "Petlawad",
    "Piploda",
    "Pithampur",
    "Porsa",
    "Punasa",
    "Raghogarh",
    "Rahatgarh",
    "Raisen",
    "Rajgarh",
    "Rajnagar",
    "Rajpur",
    "Rampura",
    "Ranapur",
    "Ratangarh",
    "Ratlam",
    "Rehli",
    "Rehti",
    "Rewa",
    "Sabalgarh",
    "Sagar",
    "Sailana",
    "Sanawad",
    "Sanchi",
    "Sanwer",
    "Sarangpur",
    "Satna",
    "Satwas",
    "Saugor",
    "Sausar",
    "Sehore",
    "Sendhwa",
    "Seondha",
    "Seoni",
    "Seoni Malwa",
    "Shahdol",
    "Shahgarh",
    "Shahpur",
    "Shahpura",
    "Shajapur",
    "Shamgarh",
    "Sheopur",
    "Shivpuri",
    "Shujalpur",
    "Sidhi",
    "Sihora",
    "Simaria",
    "Singoli",
    "Singrauli",
    "Sirmaur",
    "Sironj",
    "Sitamau",
    "Sohagi",
    "Sohagpur",
    "Sultanpur",
    "Susner",
    "Tal",
    "Talen",
    "Tarana",
    "Tekanpur",
    "Tendukheda",
    "Teonthar",
    "Thandla",
    "Tikamgarh",
    "Tirodi",
    "Udaipura",
    "Ujjain",
    "Ukwa",
    "Umaria",
    "Umaria District",
    "Umri",
    "Unhel",
    "Vidisha",
    "Waraseoni",
    "Achalpur",
    "Ahiri",
    "Ahmadnagar",
    "Ahmadpur",
    "Airoli",
    "Ajra",
    "Akalkot",
    "Akola",
    "Akot",
    "Alandi",
    "Alibag",
    "Allapalli",
    "Amalner",
    "Amarnath",
    "Ambad",
    "Ambajogai",
    "Amravati",
    "Amravati Division",
    "Anjangaon",
    "Anshing",
    "Arangaon",
    "Artist Village",
    "Arvi",
    "Ashta",
    "Ashti",
    "Aurangabad",
    "Ausa",
    "Badlapur",
    "Balapur",
    "Ballalpur",
    "Baramati",
    "Barsi",
    "Basmat",
    "Beed",
    "Bhandara",
    "Bhayandar",
    "Bhigvan",
    "Bhiwandi",
    "Bhor",
    "Bhudgaon",
    "Bhum",
    "Bhusaval",
    "Bid",
    "Biloli",
    "Boisar",
    "Borivli",
    "Buldana",
    "Chakan",
    "Chalisgaon",
    "Chanda",
    "Chandor",
    "Chandrapur",
    "Chandur",
    "Chandur Bazar",
    "Chicholi",
    "Chikhli",
    "Chinchani",
    "Chiplun",
    "Chopda",
    "Dabhol",
    "Dahanu",
    "Darwha",
    "Daryapur",
    "Dattapur",
    "Daulatabad",
    "Daund",
    "Dehu",
    "Deolali",
    "Deoli",
    "Deulgaon Raja",
    "Dharangaon",
    "Dharmabad",
    "Dharur",
    "Dhule",
    "Dhulia",
    "Diglur",
    "Digras",
    "Dombivli",
    "Dondaicha",
    "Dudhani",
    "Durgapur",
    "Erandol",
    "Faizpur",
    "Gadchiroli",
    "Gadhinglaj",
    "Gangakher",
    "Gangapur",
    "Gevrai",
    "Ghatanji",
    "Ghoti Budrukh",
    "Ghugus",
    "Gondiya",
    "Goregaon",
    "Guhagar",
    "Hadgaon",
    "Harnai",
    "Hinganghat",
    "Hingoli",
    "Hirapur Hamesha",
    "Ichalkaranji",
    "Igatpuri",
    "Indapur",
    "Jaisingpur",
    "Jalgaon",
    "Jalgaon Jamod",
    "Jalna",
    "Jawhar",
    "Jejuri",
    "Jintur",
    "Junnar",
    "Kagal",
    "Kalamb",
    "Kalamnuri",
    "Kalas",
    "Kalmeshwar",
    "Kalundri",
    "Kalyan",
    "Kamthi",
    "Kandri",
    "Kankauli",
    "Kannad",
    "Karad",
    "Karanja",
    "Karjat",
    "Karmala",
    "Kati",
    "Katol",
    "Khadki",
    "Khamgaon",
    "Khapa",
    "Kharakvasla",
    "Khed",
    "Khetia",
    "Khopoli",
    "Khuldabad",
    "Kinwat",
    "Kodoli",
    "Kolhapur",
    "Kondalwadi",
    "Kopargaon",
    "Koradi",
    "Koregaon",
    "Koynanagar",
    "Kudal",
    "Kurandvad",
    "Kurduvadi",
    "Lanja",
    "Lasalgaon",
    "Latur",
    "Lohogaon",
    "Lonar",
    "Lonavla",
    "Mahabaleshwar",
    "Mahad",
    "Maindargi",
    "Majalgaon",
    "Makhjan",
    "Malegaon",
    "Malkapur",
    "Malvan",
    "Manchar",
    "Mangrul Pir",
    "Manmad",
    "Manor",
    "Mansar",
    "Manwat",
    "Matheran",
    "Mehekar",
    "Mhasla",
    "Mhasvad",
    "Mohpa",
    "Moram",
    "Morsi",
    "Mowad",
    "Mudkhed",
    "Mukher",
    "Mul",
    "Mumbai",
    "Mumbai Suburban",
    "Murbad",
    "Murgud",
    "Murtajapur",
    "Murud",
    "Nagothana",
    "Nagpur",
    "Nagpur Division",
    "Naldurg",
    "Nanded",
    "Nandgaon",
    "Nandura Buzurg",
    "Nandurbar",
    "Nashik",
    "Nashik Division",
    "Navi Mumbai",
    "Neral",
    "Nilanga",
    "Nipani",
    "Osmanabad",
    "Ozar",
    "Pachora",
    "Paithan",
    "Palghar",
    "Panchgani",
    "Pandharpur",
    "Panhala",
    "Panvel",
    "Parbhani",
    "Parli Vaijnath",
    "Parola",
    "Partur",
    "Patan",
    "Pathardi",
    "Pathri",
    "Patur",
    "Pawni",
    "Pen",
    "Phaltan",
    "Pimpri",
    "Pipri",
    "Powai",
    "Pulgaon",
    "Pune",
    "Pune Division",
    "Purna",
    "Pusad",
    "Rahimatpur",
    "Rahuri",
    "Raigarh",
    "Rajapur",
    "Rajgurunagar",
    "Rajur",
    "Rajura",
    "Ramtek",
    "Ratnagiri",
    "Raver",
    "Revadanda",
    "Risod",
    "Roha",
    "Sangamner",
    "Sangli",
    "Sangola",
    "Saoner",
    "Sasvad",
    "Satana",
    "Satara",
    "Satara Division",
    "Savantvadi",
    "Savda",
    "Selu",
    "Shahada",
    "Shahapur",
    "Shegaon",
    "Shiraguppi",
    "Shirdi",
    "Shirgaon",
    "Shirpur",
    "Shirwal",
    "Shivaji Nagar",
    "Shrigonda",
    "Sillod",
    "Sindhudurg",
    "Sindi",
    "Sinnar",
    "Sirur",
    "Solapur",
    "Sonegaon",
    "Soygaon",
    "Srivardhan",
    "Surgana",
    "Talegaon Dabhade",
    "Taloda",
    "Tarapur",
    "Tasgaon",
    "Telhara",
    "Thane",
    "Trimbak",
    "Tuljapur",
    "Tumsar",
    "Udgir",
    "Ulhasnagar",
    "Umarga",
    "Umarkhed",
    "Umred",
    "Uran",
    "Vada",
    "Vaijapur",
    "Varangaon",
    "Vasind",
    "Vengurla",
    "Virar",
    "Vite",
    "Wadgaon",
    "Wai",
    "Wani",
    "Wardha",
    "Warora",
    "Warud",
    "Washim",
    "Yaval",
    "Yavatmal",
    "Yeola",
    "Bishnupur",
    "Churachandpur",
    "Imphal",
    "Kakching",
    "Mayang Imphal",
    "Moirang",
    "Phek",
    "Senapati",
    "Tamenglong",
    "Thoubal",
    "Ukhrul",
    "Wangjing",
    "Yairipok",
    "Cherrapunji",
    "East Garo Hills",
    "East Jaintia Hills",
    "East Khasi Hills",
    "Mairang",
    "Mankachar",
    "Nongpoh",
    "Nongstoin",
    "North Garo Hills",
    "Ri-Bhoi",
    "Shillong",
    "South Garo Hills",
    "South West Garo Hills",
    "South West Khasi Hills",
    "Tura",
    "West Garo Hills",
    "West Jaintia Hills",
    "West Khasi Hills",
    "Aizawl",
    "Champhai",
    "Darlawn",
    "Khawhai",
    "Kolasib",
    "Kolasib district",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "North Vanlaiphai",
    "Saiha",
    "Sairang",
    "Saitlaw",
    "Serchhip",
    "Thenzawl",
    "Dimapur",
    "Kohima",
    "Mokokchung",
    "Mon",
    "Peren",
    "Phek",
    "Tuensang",
    "Tuensang District",
    "Wokha",
    "Zunheboto",
    "Angul",
    "Angul District",
    "Asika",
    "Athagarh",
    "Bada Barabil",
    "Balangir",
    "Balasore",
    "Baleshwar",
    "Balimila",
    "Balugaon",
    "Banapur",
    "Banki",
    "Banposh",
    "Baragarh",
    "Barbil",
    "Bargarh",
    "Barpali",
    "Basudebpur",
    "Baud",
    "Baudh",
    "Belaguntha",
    "Bhadrak",
    "Bhadrakh",
    "Bhanjanagar",
    "Bhawanipatna",
    "Bhuban",
    "Bhubaneshwar",
    "Binka",
    "Birmitrapur",
    "Bolanikhodan",
    "Brahmapur",
    "Brajarajnagar",
    "Buguda",
    "Burla",
    "Champua",
    "Chandbali",
    "Chatrapur",
    "Chikitigarh",
    "Chittarkonda",
    "Cuttack",
    "Daitari",
    "Deogarh",
    "Dhenkanal",
    "Digapahandi",
    "Gajapati",
    "Ganjam",
    "Gopalpur",
    "Gudari",
    "Gunupur",
    "Hinjilikatu",
    "Hirakud",
    "Jagatsinghapur",
    "Jagatsinghpur",
    "Jajpur",
    "Jaleshwar",
    "Jatani",
    "Jeypore",
    "Jharsuguda",
    "Jharsuguda District",
    "Kaintragarh",
    "Kalahandi",
    "Kamakhyanagar",
    "Kandhamal",
    "Kantabanji",
    "Kantilo",
    "Kendrapara",
    "Kendraparha",
    "Kendujhar",
    "Kesinga",
    "Khallikot",
    "Kharhial",
    "Khordha",
    "Khurda",
    "Kiri Buru",
    "Kodala",
    "Konarka",
    "Koraput",
    "Kuchaiburi",
    "Kuchinda",
    "Malakanagiri",
    "Malkangiri",
    "Mayurbhanj",
    "Nabarangpur",
    "Nayagarh",
    "Nayagarh District",
    "Nilgiri",
    "Nimaparha",
    "Nowrangapur",
    "Nuapada",
    "Padampur",
    "Paradip Garh",
    "Patamundai",
    "Patnagarh",
    "Phulbani",
    "Pipili",
    "Polasara",
    "Puri",
    "Purushottampur",
    "Rambha",
    "Raurkela",
    "Rayagada",
    "Remuna",
    "Rengali",
    "Sambalpur",
    "Sonepur",
    "Sorada",
    "Soro",
    "Subarnapur",
    "Sundargarh",
    "Talcher",
    "Tarabha",
    "Titlagarh",
    "Udayagiri",
    "Karaikal",
    "Mahe",
    "Puducherry",
    "Yanam",
    "Abohar",
    "Adampur",
    "Ajitgarh",
    "Ajnala",
    "Akalgarh",
    "Alawalpur",
    "Amloh",
    "Amritsar",
    "Anandpur Sahib",
    "Badhni Kalan",
    "Bagha Purana",
    "Bakloh",
    "Balachor",
    "Banga",
    "Banur",
    "Barnala",
    "Batala",
    "Begowal",
    "Bhadaur",
    "Bhatinda",
    "Bhawanigarh",
    "Bhikhi",
    "Bhogpur",
    "Bholath",
    "Budhlada",
    "Chima",
    "Dasuya",
    "Dera Baba Nanak",
    "Dera Bassi",
    "Dhanaula",
    "Dhariwal",
    "Dhilwan",
    "Dhudi",
    "Dhuri",
    "Dina Nagar",
    "Dirba",
    "Doraha",
    "Faridkot",
    "Fatehgarh Churian",
    "Fatehgarh Sahib",
    "Fazilka",
    "Firozpur",
    "Firozpur District",
    "Gardhiwala",
    "Garhshankar",
    "Ghanaur",
    "Giddarbaha",
    "Gurdaspur",
    "Guru Har Sahai",
    "Hajipur",
    "Hariana",
    "Hoshiarpur",
    "Ishanpur",
    "Jagraon",
    "Jaito",
    "Jalalabad",
    "Jalandhar",
    "Jandiala",
    "Jandiala Guru",
    "Kalanaur",
    "Kapurthala",
    "Kartarpur",
    "Khamanon",
    "Khanna",
    "Kharar",
    "Khemkaran",
    "Kot Isa Khan",
    "Kotkapura",
    "Laungowal",
    "Ludhiana",
    "Machhiwara",
    "Majitha",
    "Makhu",
    "Malaut",
    "Malerkotla",
    "Mansa",
    "Maur Mandi",
    "Moga",
    "Mohali",
    "Morinda",
    "Mukerian",
    "Nabha",
    "Nakodar",
    "Nangal",
    "Nawanshahr",
    "Nurmahal",
    "Nurpur Kalan",
    "Pathankot",
    "Patiala",
    "Patti",
    "Phagwara",
    "Phillaur",
    "Qadian",
    "Rahon",
    "Raikot",
    "Rajasansi",
    "Rajpura",
    "Ram Das",
    "Rampura",
    "Rupnagar",
    "Samrala",
    "Sanaur",
    "Sangrur",
    "Sardulgarh",
    "Shahid Bhagat Singh Nagar",
    "Shahkot",
    "Sham Churasi",
    "Sirhind-Fategarh",
    "Sri Muktsar Sahib",
    "Sultanpur Lodhi",
    "Sunam",
    "Talwandi Bhai",
    "Talwara",
    "Tarn Taran Sahib",
    "Zira",
    "Abhaneri",
    "Abu",
    "Abu Road",
    "Ajmer",
    "Aklera",
    "Alwar",
    "Amet",
    "Anta",
    "Anupgarh",
    "Asind",
    "Bagar",
    "Bakani",
    "Bali",
    "Balotra",
    "Bandikui",
    "Banswara",
    "Baran",
    "Bari",
    "Bari Sadri",
    "Barmer",
    "Basi",
    "Basni",
    "Baswa",
    "Bayana",
    "Beawar",
    "Begun",
    "Behror",
    "Bhadasar",
    "Bhadra",
    "Bharatpur",
    "Bhasawar",
    "Bhilwara",
    "Bhindar",
    "Bhinmal",
    "Bhiwadi",
    "Bhuma",
    "Bikaner",
    "Bilara",
    "Bissau",
    "Borkhera",
    "Bundi",
    "Chaksu",
    "Chechat",
    "Chhabra",
    "Chhapar",
    "Chhoti Sadri",
    "Chidawa",
    "Chittaurgarh",
    "Churu",
    "Dariba",
    "Dausa",
    "Deoli",
    "Deshnoke",
    "Devgarh",
    "Dhaulpur",
    "Didwana",
    "Dig",
    "Dungarpur",
    "Fatehpur",
    "Galiakot",
    "Ganganagar",
    "Gangapur",
    "Govindgarh",
    "Gulabpura",
    "Hanumangarh",
    "Hindaun",
    "Jahazpur",
    "Jaipur",
    "Jaisalmer",
    "Jaitaran",
    "Jalor",
    "Jalore",
    "Jhalawar",
    "Jhalrapatan",
    "Jhunjhunun",
    "Jobner",
    "Jodhpur",
    "Kaman",
    "Kanor",
    "Kapren",
    "Karanpur",
    "Karauli",
    "Kekri",
    "Keshorai Patan",
    "Khandela",
    "Khanpur",
    "Khetri",
    "Kishangarh",
    "Kota",
    "Kotputli",
    "Kuchaman",
    "Kuchera",
    "Kumher",
    "Kushalgarh",
    "Lachhmangarh Sikar",
    "Ladnun",
    "Lakheri",
    "Lalsot",
    "Losal",
    "Mahwah",
    "Makrana",
    "Malpura",
    "Mandal",
    "Mandalgarh",
    "Mandawar",
    "Mangrol",
    "Manohar Thana",
    "Manoharpur",
    "Meethari Marwar",
    "Merta",
    "Mundwa",
    "Nadbai",
    "Nagar",
    "Nagaur",
    "Nainwa",
    "Napasar",
    "Naraina",
    "Nasirabad",
    "Nathdwara",
    "Nawa",
    "Nawalgarh",
    "Neem ka Thana",
    "Nimaj",
    "Nimbahera",
    "Niwai",
    "Nohar",
    "Nokha",
    "Padampur",
    "Pali",
    "Partapur",
    "Parvatsar",
    "Phalodi",
    "Phulera",
    "Pilani",
    "Pilibangan",
    "Pindwara",
    "Pipar",
    "Pirawa",
    "Pokaran",
    "Pratapgarh",
    "Pushkar",
    "Raipur",
    "Raisinghnagar",
    "Rajakhera",
    "Rajaldesar",
    "Rajgarh",
    "Rajsamand",
    "Ramganj Mandi",
    "Ramgarh",
    "Rani",
    "Ratangarh",
    "Rawatbhata",
    "Rawatsar",
    "Ringas",
    "Sadri",
    "Salumbar",
    "Sambhar",
    "Samdari",
    "Sanchor",
    "Sangaria",
    "Sangod",
    "Sardarshahr",
    "Sarwar",
    "Sawai Madhopur",
    "Shahpura",
    "Sheoganj",
    "Sikar",
    "Sirohi",
    "Siwana",
    "Sojat",
    "Sri Dungargarh",
    "Sri Madhopur",
    "Sujangarh",
    "Suket",
    "Sunel",
    "Surajgarh",
    "Suratgarh",
    "Takhatgarh",
    "Taranagar",
    "Tijara",
    "Todabhim",
    "Todaraisingh",
    "Tonk",
    "Udaipur",
    "Udpura",
    "Uniara",
    "Wer",
    "East District",
    "Gangtok",
    "Gyalshing",
    "Jorethang",
    "Mangan",
    "Namchi",
    "Naya Bazar",
    "North District",
    "Rangpo",
    "Singtam",
    "South District",
    "West District",
    "Abiramam",
    "Adirampattinam",
    "Aduthurai",
    "Alagapuram",
    "Alandur",
    "Alanganallur",
    "Alangayam",
    "Alangudi",
    "Alangulam",
    "Alappakkam",
    "Alwa Tirunagari",
    "Ambasamudram",
    "Ambattur",
    "Ambur",
    "Ammapettai",
    "Anamalais",
    "Andippatti",
    "Annamalainagar",
    "Annavasal",
    "Annur",
    "Anthiyur",
    "Arakkonam",
    "Arantangi",
    "Arcot",
    "Arimalam",
    "Ariyalur",
    "Arni",
    "Arumbavur",
    "Arumuganeri",
    "Aruppukkottai",
    "Aruvankad",
    "Attayyampatti",
    "Attur",
    "Auroville",
    "Avadi",
    "Avinashi",
    "Ayakudi",
    "Ayyampettai",
    "Belur",
    "Bhavani",
    "Bodinayakkanur",
    "Chengam",
    "Chennai",
    "Chennimalai",
    "Chetput",
    "Chettipalaiyam",
    "Cheyyar",
    "Cheyyur",
    "Chidambaram",
    "Chingleput",
    "Chinna Salem",
    "Chinnamanur",
    "Chinnasekkadu",
    "Cholapuram",
    "Coimbatore",
    "Colachel",
    "Cuddalore",
    "Cumbum",
    "Denkanikota",
    "Desur",
    "Devadanappatti",
    "Devakottai",
    "Dhali",
    "Dharapuram",
    "Dharmapuri",
    "Dindigul",
    "Dusi",
    "Elayirampannai",
    "Elumalai",
    "Eral",
    "Eraniel",
    "Erode",
    "Erumaippatti",
    "Ettaiyapuram",
    "Gandhi Nagar",
    "Gangaikondan",
    "Gangavalli",
    "Gingee",
    "Gobichettipalayam",
    "Gudalur",
    "Gudiyatham",
    "Guduvancheri",
    "Gummidipundi",
    "Harur",
    "Hosur",
    "Idappadi",
    "Ilampillai",
    "Iluppur",
    "Injambakkam",
    "Irugur",
    "Jalakandapuram",
    "Jalarpet",
    "Jayamkondacholapuram",
    "Kadambur",
    "Kadayanallur",
    "Kalakkadu",
    "Kalavai",
    "Kallakkurichchi",
    "Kallidaikurichi",
    "Kallupatti",
    "Kalugumalai",
    "Kamuthi",
    "Kanadukattan",
    "Kancheepuram",
    "Kanchipuram",
    "Kangayam",
    "Kanniyakumari",
    "Karaikkudi",
    "Karamadai",
    "Karambakkudi",
    "Kariapatti",
    "Karumbakkam",
    "Karur",
    "Katpadi",
    "Kattivakkam",
    "Kattupputtur",
    "Kaveripatnam",
    "Kayalpattinam",
    "Kayattar",
    "Keelakarai",
    "Kelamangalam",
    "Kil Bhuvanagiri",
    "Kilvelur",
    "Kiranur",
    "Kodaikanal",
    "Kodumudi",
    "Kombai",
    "Konganapuram",
    "Koothanallur",
    "Koradachcheri",
    "Korampallam",
    "Kotagiri",
    "Kottaiyur",
    "Kovilpatti",
    "Krishnagiri",
    "Kulattur",
    "Kulittalai",
    "Kumaralingam",
    "Kumbakonam",
    "Kunnattur",
    "Kurinjippadi",
    "Kuttalam",
    "Kuzhithurai",
    "Lalgudi",
    "Madambakkam",
    "Madipakkam",
    "Madukkarai",
    "Madukkur",
    "Madurai",
    "Madurantakam",
    "Mallapuram",
    "Mallasamudram",
    "Mallur",
    "Manali",
    "Manalurpettai",
    "Manamadurai",
    "Manappakkam",
    "Manapparai",
    "Manavalakurichi",
    "Mandapam",
    "Mangalam",
    "Mannargudi",
    "Marakkanam",
    "Marandahalli",
    "Masinigudi",
    "Mattur",
    "Mayiladuthurai",
    "Melur",
    "Mettuppalaiyam",
    "Mettur",
    "Minjur",
    "Mohanur",
    "Mudukulattur",
    "Mulanur",
    "Musiri",
    "Muttupet",
    "Naduvattam",
    "Nagapattinam",
    "Nagercoil",
    "Namagiripettai",
    "Namakkal",
    "Nambiyur",
    "Nambutalai",
    "Nandambakkam",
    "Nangavalli",
    "Nangilickondan",
    "Nanguneri",
    "Nannilam",
    "Naravarikuppam",
    "Nattam",
    "Nattarasankottai",
    "Needamangalam",
    "Neelankarai",
    "Negapatam",
    "Nellikkuppam",
    "Nilakottai",
    "Nilgiris",
    "Odugattur",
    "Omalur",
    "Ooty",
    "Padmanabhapuram",
    "Palakkodu",
    "Palamedu",
    "Palani",
    "Palavakkam",
    "Palladam",
    "Pallappatti",
    "Pallattur",
    "Pallavaram",
    "Pallikondai",
    "Pallipattu",
    "Pallippatti",
    "Panruti",
    "Papanasam",
    "Papireddippatti",
    "Papparappatti",
    "Paramagudi",
    "Pattukkottai",
    "Pennadam",
    "Pennagaram",
    "Pennathur",
    "Peraiyur",
    "Perambalur",
    "Peranamallur",
    "Peranampattu",
    "Peravurani",
    "Periyakulam",
    "Periyanayakkanpalaiyam",
    "Periyanegamam",
    "Periyapatti",
    "Periyapattinam",
    "Perundurai",
    "Perungudi",
    "Perur",
    "Pollachi",
    "Polur",
    "Ponnamaravati",
    "Ponneri",
    "Poonamalle",
    "Porur",
    "Pudukkottai",
    "Puduppatti",
    "Pudur",
    "Puduvayal",
    "Puliyangudi",
    "Puliyur",
    "Pullambadi",
    "Punjai Puliyampatti",
    "Rajapalaiyam",
    "Ramanathapuram",
    "Rameswaram",
    "Rasipuram",
    "Saint Thomas Mount",
    "Salem",
    "Sathankulam",
    "Sathyamangalam",
    "Sattur",
    "Sayalkudi",
    "Seven Pagodas",
    "Sholinghur",
    "Singanallur",
    "Singapperumalkovil",
    "Sirkazhi",
    "Sirumugai",
    "Sivaganga",
    "Sivagiri",
    "Sivakasi",
    "Srimushnam",
    "Sriperumbudur",
    "Srivaikuntam",
    "Srivilliputhur",
    "Suchindram",
    "Sulur",
    "Surandai",
    "Swamimalai",
    "Tambaram",
    "Tanjore",
    "Taramangalam",
    "Tattayyangarpettai",
    "Thanjavur",
    "Tharangambadi",
    "Theni",
    "Thenkasi",
    "Thirukattupalli",
    "Thiruthani",
    "Thiruvaiyaru",
    "Thiruvallur",
    "Thiruvarur",
    "Thiruvidaimaruthur",
    "Thoothukudi",
    "Tindivanam",
    "Tinnanur",
    "Tiruchchendur",
    "Tiruchengode",
    "Tiruchirappalli",
    "Tirukkoyilur",
    "Tirumullaivasal",
    "Tirunelveli",
    "Tirunelveli Kattabo",
    "Tiruppalaikudi",
    "Tirupparangunram",
    "Tiruppur",
    "Tiruppuvanam",
    "Tiruttangal",
    "Tiruvannamalai",
    "Tiruvottiyur",
    "Tisaiyanvilai",
    "Tondi",
    "Turaiyur",
    "Udangudi",
    "Udumalaippettai",
    "Uppiliyapuram",
    "Usilampatti",
    "Uttamapalaiyam",
    "Uttiramerur",
    "Ūttukkuli",
    "V.S.K.Valasai (Dindigul-Dist.)",
    "Vadakku Valliyur",
    "Vadakku Viravanallur",
    "Vadamadurai",
    "Vadippatti",
    "Valangaiman",
    "Valavanur",
    "Vallam",
    "Valparai",
    "Vandalur",
    "Vandavasi",
    "Vaniyambadi",
    "Vasudevanallur",
    "Vattalkundu",
    "Vedaraniyam",
    "Vedasandur",
    "Velankanni",
    "Vellanur",
    "Vellore",
    "Velur",
    "Vengavasal",
    "Vettaikkaranpudur",
    "Vettavalam",
    "Vijayapuri",
    "Vikravandi",
    "Vilattikulam",
    "Villupuram",
    "Viraganur",
    "Virudhunagar",
    "Vriddhachalam",
    "Walajapet",
    "Wallajahbad",
    "Wellington",
    "Adilabad",
    "Alampur",
    "Andol",
    "Asifabad",
    "Balapur",
    "Banswada",
    "Bellampalli",
    "Bhadrachalam",
    "Bhadradri Kothagudem",
    "Bhaisa",
    "Bhongir",
    "Bodhan",
    "Chandur",
    "Chatakonda",
    "Dasnapur",
    "Devarkonda",
    "Dornakal",
    "Farrukhnagar",
    "Gaddi Annaram",
    "Gadwal",
    "Ghatkesar",
    "Gopalur",
    "Gudur",
    "Hyderabad",
    "Jagtial",
    "Jangaon",
    "Jangoan",
    "Jayashankar Bhupalapally",
    "Jogulamba Gadwal",
    "Kagaznagar",
    "Kamareddi",
    "Kamareddy",
    "Karimnagar",
    "Khammam",
    "Kodar",
    "Koratla",
    "Kothapet",
    "Kottagudem",
    "Kottapalli",
    "Kukatpalli",
    "Kyathampalle",
    "Lakshettipet",
    "Lal Bahadur Nagar",
    "Mahabubabad",
    "Mahbubnagar",
    "Malkajgiri",
    "Mancheral",
    "Mandamarri",
    "Manthani",
    "Manuguru",
    "Medak",
    "Medchal",
    "Medchal Malkajgiri",
    "Mirialguda",
    "Nagar Karnul",
    "Nalgonda",
    "Narayanpet",
    "Narsingi",
    "Naspur",
    "Nirmal",
    "Nizamabad",
    "Paloncha",
    "Palwancha",
    "Patancheru",
    "Peddapalli",
    "Quthbullapur",
    "Rajanna Sircilla",
    "Ramagundam",
    "Ramgundam",
    "Rangareddi",
    "Sadaseopet",
    "Sangareddi",
    "Sathupalli",
    "Secunderabad",
    "Serilingampalle",
    "Siddipet",
    "Singapur",
    "Sirpur",
    "Sirsilla",
    "Sriramnagar",
    "Suriapet",
    "Tandur",
    "Uppal Kalan",
    "Vemalwada",
    "Vikarabad",
    "Wanparti",
    "Warangal",
    "Yellandu",
    "Zahirabad",
    "Agartala",
    "Amarpur",
    "Ambasa",
    "Barjala",
    "Belonia",
    "Dhalai",
    "Dharmanagar",
    "Gomati",
    "Kailashahar",
    "Kamalpur",
    "Khowai",
    "North Tripura",
    "Ranir Bazar",
    "Sabrum",
    "Sonamura",
    "South Tripura",
    "Udaipur",
    "Unakoti",
    "West Tripura",
    "Achhnera",
    "Afzalgarh",
    "Agra",
    "Ahraura",
    "Akbarpur",
    "Aliganj",
    "Aligarh",
    "Allahganj",
    "Amanpur",
    "Ambahta",
    "Ambedkar Nagar",
    "Amethi",
    "Amroha",
    "Anandnagar",
    "Antu",
    "Anupshahr",
    "Aonla",
    "Atarra",
    "Atrauli",
    "Atraulia",
    "Auraiya",
    "Auras",
    "Ayodhya",
    "Azamgarh",
    "Baberu",
    "Babina",
    "Babrala",
    "Babugarh",
    "Bachhraon",
    "Bachhrawan",
    "Baghpat",
    "Bah",
    "Baheri",
    "Bahjoi",
    "Bahraich",
    "Bahraigh",
    "Bahsuma",
    "Bahua",
    "Bajna",
    "Bakewar",
    "Baldev",
    "Ballia",
    "Balrampur",
    "Banat",
    "Banbasa",
    "Banda",
    "Bangarmau",
    "Bansdih",
    "Bansgaon",
    "Bansi",
    "Bara Banki",
    "Baragaon",
    "Baraut",
    "Bareilly",
    "Barkhera Kalan",
    "Barsana",
    "Basti",
    "Behat",
    "Bela",
    "Beniganj",
    "Beswan",
    "Bewar",
    "Bhadohi",
    "Bhagwantnagar",
    "Bharthana",
    "Bharwari",
    "Bhinga",
    "Bhongaon",
    "Bidhuna",
    "Bighapur Khurd",
    "Bijnor",
    "Bikapur",
    "Bilari",
    "Bilariaganj",
    "Bilaspur",
    "Bilgram",
    "Bilhaur",
    "Bilsanda",
    "Bilsi",
    "Bilthra",
    "Bindki",
    "Bisalpur",
    "Bisauli",
    "Bisenda Buzurg",
    "Bishunpur Urf Maharajganj",
    "Biswan",
    "Bithur",
    "Budaun",
    "Budhana",
    "Bulandshahr",
    "Captainganj",
    "Chail",
    "Chakia",
    "Chandauli",
    "Chandauli District",
    "Chandpur",
    "Chanduasi",
    "Charkhari",
    "Charthawal",
    "Chhaprauli",
    "Chharra",
    "Chhata",
    "Chhibramau",
    "Chhutmalpur",
    "Chillupar",
    "Chirgaon",
    "Chitrakoot",
    "Chopan",
    "Chunar",
    "Colonelganj",
    "Dadri",
    "Dalmau",
    "Dankaur",
    "Dasna",
    "Dataganj",
    "Daurala",
    "Dayal Bagh",
    "Deoband",
    "Deoranian",
    "Deoria",
    "Dewa",
    "Dhampur",
    "Dhanaura",
    "Dhaurahra",
    "Dibai",
    "Dohrighat",
    "Dostpur",
    "Dudhi",
    "Etah",
    "Etawah",
    "Faizabad",
    "Farah",
    "Faridnagar",
    "Faridpur",
    "Farrukhabad",
    "Fatehabad",
    "Fatehganj West",
    "Fatehgarh",
    "Fatehpur",
    "Fatehpur Chaurasi",
    "Fatehpur Sikri",
    "Firozabad",
    "Fyzabad",
    "Gajraula",
    "Gangoh",
    "Ganj Dundwara",
    "Ganj Muradabad",
    "Garautha",
    "Garhi Pukhta",
    "Garhmuktesar",
    "Gautam Buddha Nagar",
    "Gawan",
    "Ghatampur",
    "Ghaziabad",
    "Ghazipur",
    "Ghiror",
    "Ghorawal",
    "Ghosi",
    "Gohand",
    "Gokul",
    "Gola Bazar",
    "Gola Gokarannath",
    "Gonda",
    "Gonda City",
    "Gopamau",
    "Gorakhpur",
    "Goshainganj",
    "Govardhan",
    "Greater Noida",
    "Gulaothi",
    "Gunnaur",
    "Gursahaiganj",
    "Gursarai",
    "Gyanpur",
    "Haldaur",
    "Hamirpur",
    "Handia",
    "Hapur",
    "Haraiya",
    "Hardoi",
    "Harduaganj",
    "Hasanpur",
    "Hastinapur",
    "Hata",
    "Hathras",
    "Iglas",
    "Ikauna",
    "Indergarh",
    "Islamnagar",
    "Itaunja",
    "Itimadpur",
    "Jagdishpur",
    "Jagnair",
    "Jahanabad",
    "Jahangirabad",
    "Jahangirpur",
    "Jainpur",
    "Jais",
    "Jalalabad",
    "Jalali",
    "Jalalpur",
    "Jalaun",
    "Jalesar",
    "Jansath",
    "Jarwal",
    "Jasrana",
    "Jaswantnagar",
    "Jaunpur",
    "Jewar",
    "Jhalu",
    "Jhansi",
    "Jhinjhak",
    "Jhinjhana",
    "Jhusi",
    "Jyotiba Phule Nagar",
    "Kabrai",
    "Kachhwa",
    "Kadaura",
    "Kadipur",
    "Kaimganj",
    "Kairana",
    "Kakori",
    "Kakrala",
    "Kalinagar",
    "Kalpi",
    "Kamalganj",
    "Kampil",
    "Kandhla",
    "Kannauj",
    "Kanpur",
    "Kanpur Dehat",
    "Kant",
    "Kanth",
    "Karari",
    "Karhal",
    "Kasganj",
    "Katra",
    "Kaushambi District",
    "Kemri",
    "Khada",
    "Khaga",
    "Khair",
    "Khairabad",
    "Khalilabad",
    "Khanpur",
    "Kharela",
    "Khargupur",
    "Kharkhauda",
    "Khatauli",
    "Khekra",
    "Kheri",
    "Khudaganj",
    "Khurja",
    "Khutar",
    "Kirakat",
    "Kiraoli",
    "Kiratpur",
    "Kishanpur",
    "Kishni",
    "Kithor",
    "Konch",
    "Kopaganj",
    "Kosi",
    "Kota",
    "Kotra",
    "Kulpahar",
    "Kunda",
    "Kundarkhi",
    "Kurara",
    "Kushinagar",
    "Laharpur",
    "Lakhimpur",
    "Lakhna",
    "Lalganj",
    "Lalitpur",
    "Lar",
    "Lawar Khas",
    "Loni",
    "Lucknow",
    "Lucknow District",
    "Machhlishahr",
    "Madhoganj",
    "Madhogarh",
    "Maghar",
    "Mahaban",
    "Maharajganj",
    "Mahmudabad",
    "Mahoba",
    "Maholi",
    "Mahroni",
    "Mailani",
    "Mainpuri",
    "Malihabad",
    "Mandawar",
    "Maniar",
    "Manikpur",
    "Manjhanpur",
    "Mankapur",
    "Marahra",
    "Mariahu",
    "Mataundh",
    "Mathura",
    "Mau",
    "Mau Aimma",
    "Maudaha",
    "Mauranwan",
    "Mawana",
    "Meerut",
    "Mehnagar",
    "Mehndawal",
    "Milak",
    "Miranpur",
    "Miranpur Katra",
    "Mirganj",
    "Mirzapur",
    "Misrikh",
    "Mohan",
    "Mohanpur",
    "Moradabad",
    "Moth",
    "Mubarakpur",
    "Mughal Sarai",
    "Muhammadabad",
    "Muradnagar",
    "Mursan",
    "Musafir-Khana",
    "Muzaffarnagar",
    "Nadigaon",
    "Nagina",
    "Nagram",
    "Najibabad",
    "Nakur",
    "Nanauta",
    "Nandgaon",
    "Nanpara",
    "Narauli",
    "Naraura",
    "Nautanwa",
    "Nawabganj",
    "Nichlaul",
    "Nihtaur",
    "Niwari",
    "Nizamabad",
    "Noida",
    "Nurpur",
    "Obra",
    "Orai",
    "Oran",
    "Pachperwa",
    "Padrauna",
    "Pahasu",
    "Pali",
    "Palia Kalan",
    "Parichha",
    "Parichhatgarh",
    "Parshadepur",
    "Patiali",
    "Patti",
    "Pawayan",
    "Phalauda",
    "Phaphund",
    "Phariha",
    "Phulpur",
    "Pihani",
    "Pilibhit",
    "Pilkhua",
    "Pinahat",
    "Pipraich",
    "Pratapgarh",
    "Prayagraj (Allahabad)",
    "Pukhrayan",
    "Puranpur",
    "Purwa",
    "Rabupura",
    "Radhakund",
    "Raebareli",
    "Rajapur",
    "Ramkola",
    "Ramnagar",
    "Rampur",
    "Rampura",
    "Ranipur",
    "Rasra",
    "Rasulabad",
    "Rath",
    "Raya",
    "Renukut",
    "Reoti",
    "Richha",
    "Robertsganj",
    "Rudarpur",
    "Rura",
    "Sadabad",
    "Sadat",
    "Safipur",
    "Saharanpur",
    "Sahaspur",
    "Sahaswan",
    "Sahawar",
    "Saidpur",
    "Sakit",
    "Salon",
    "Sambhal",
    "Samthar",
    "Sandi",
    "Sandila",
    "Sant Kabir Nagar",
    "Sant Ravi Das Nagar",
    "Sarai Akil",
    "Sarai Ekdil",
    "Sarai Mir",
    "Sarauli",
    "Sardhana",
    "Sarila",
    "Sasni",
    "Satrikh",
    "Saurikh",
    "Sector",
    "Seohara",
    "Shahabad",
    "Shahganj",
    "Shahi",
    "Shahjahanpur",
    "Shahpur",
    "Shamli",
    "Shamsabad",
    "Shankargarh",
    "Shergarh",
    "Sherkot",
    "Shikarpur",
    "Shikohabad",
    "Shishgarh",
    "Shrawasti",
    "Siddharthnagar",
    "Sidhauli",
    "Sidhpura",
    "Sikandarabad",
    "Sikandarpur",
    "Sikandra",
    "Sikandra Rao",
    "Sirathu",
    "Sirsa",
    "Sirsaganj",
    "Sirsi",
    "Sisauli",
    "Siswa Bazar",
    "Sitapur",
    "Sonbhadra",
    "Soron",
    "Suar",
    "Sultanpur",
    "Surianwan",
    "Tajpur",
    "Talbahat",
    "Talgram",
    "Tanda",
    "Thakurdwara",
    "Thana Bhawan",
    "Tikaitnagar",
    "Tikri",
    "Tilhar",
    "Tindwari",
    "Titron",
    "Tori-Fatehpur",
    "Tulsipur",
    "Tundla",
    "Ugu",
    "Ujhani",
    "Ūn",
    "Unnao",
    "Usehat",
    "Utraula",
    "Varanasi",
    "Vrindavan",
    "Wazirganj",
    "Zafarabad",
    "Zaidpur",
    "Zamania",
    "Almora",
    "Bageshwar",
    "Barkot",
    "Bazpur",
    "Bhim Tal",
    "Bhowali",
    "Birbhaddar",
    "Chakrata",
    "Chamoli",
    "Champawat",
    "Clement Town",
    "Dehradun",
    "Devaprayag",
    "Dharchula",
    "Doiwala",
    "Dugadda",
    "Dwarahat",
    "Garhwal",
    "Haldwani",
    "Harbatpur",
    "Haridwar",
    "Jaspur",
    "Joshimath",
    "Kaladhungi",
    "Kalagarh Project Colony",
    "Kashipur",
    "Khatima",
    "Kichha",
    "Kotdwara",
    "Laksar",
    "Lansdowne",
    "Lohaghat",
    "Manglaur",
    "Mussoorie",
    "Naini Tal",
    "Narendranagar",
    "Pauri",
    "Pipalkoti",
    "Pithoragarh",
    "Raipur",
    "Raiwala Bara",
    "Ramnagar",
    "Ranikhet",
    "Rishikesh",
    "Roorkee",
    "Rudraprayag",
    "Sitarganj",
    "Srinagar",
    "Sultanpur",
    "Tanakpur",
    "Tehri",
    "Tehri-Garhwal",
    "Udham Singh Nagar",
    "Uttarkashi",
    "Vikasnagar",
    "Ahmedpur",
    "Aistala",
    "Aknapur",
    "Alipurduar",
    "Amlagora",
    "Amta",
    "Amtala",
    "Andal",
    "Arambagh",
    "Asansol",
    "Ashoknagar Kalyangarh",
    "Badkulla",
    "Baduria",
    "Bagdogra",
    "Bagnan",
    "Bagula",
    "Bahula",
    "Baidyabati",
    "Bakreswar",
    "Balarampur",
    "Bali Chak",
    "Bally",
    "Balurghat",
    "Bamangola",
    "Baneswar",
    "Bangaon",
    "Bankra",
    "Bankura",
    "Bansberia",
    "Bansihari",
    "Barabazar",
    "Baranagar",
    "Barasat",
    "Bardhaman",
    "Barjora",
    "Barrackpore",
    "Baruipur",
    "Basanti",
    "Basirhat",
    "Bawali",
    "Begampur",
    "Belda",
    "Beldanga",
    "Beliatore",
    "Berhampore",
    "Bhadreswar",
    "Bhandardaha",
    "Bhatpara",
    "Birbhum district",
    "Birpara",
    "Bishnupur",
    "Bolpur",
    "Budge Budge",
    "Canning",
    "Chakapara",
    "Chakdaha",
    "Champadanga",
    "Champahati",
    "Champdani",
    "Chandannagar",
    "Chandrakona",
    "Chittaranjan",
    "Churulia",
    "Contai",
    "Cooch Behar",
    "Cossimbazar",
    "Dakshin Dinajpur district",
    "Dalkola",
    "Dam Dam",
    "Darjeeling",
    "Daulatpur",
    "Debagram",
    "Debipur",
    "Dhaniakhali",
    "Dhulagari",
    "Dhulian",
    "Dhupguri",
    "Diamond Harbour",
    "Digha",
    "Dinhata",
    "Domjur",
    "Dubrajpur",
    "Durgapur",
    "Egra",
    "Falakata",
    "Farakka",
    "Fort Gloster",
    "Gaighata",
    "Gairkata",
    "Gangadharpur",
    "Gangarampur",
    "Garui",
    "Garulia",
    "Ghatal",
    "Giria",
    "Gobardanga",
    "Gobindapur",
    "Gopalpur",
    "Gopinathpur",
    "Gorubathan",
    "Gosaba",
    "Gosanimari",
    "Gurdaha",
    "Guskhara",
    "Habra",
    "Haldia",
    "Haldibari",
    "Halisahar",
    "Harindanga",
    "Haringhata",
    "Haripur",
    "Hasimara",
    "Hindusthan Cables Town",
    "Hooghly district",
    "Howrah",
    "Ichapur",
    "Indpur",
    "Ingraj Bazar",
    "Islampur",
    "Jafarpur",
    "Jaigaon",
    "Jalpaiguri",
    "Jamuria",
    "Jangipur",
    "Jaynagar Majilpur",
    "Jejur",
    "Jhalida",
    "Jhargram",
    "Jhilimili",
    "Kakdwip",
    "Kalaikunda",
    "Kaliaganj",
    "Kalimpong",
    "Kalna",
    "Kalyani",
    "Kamarhati",
    "Kamarpukur",
    "Kanchrapara",
    "Kandi",
    "Karimpur",
    "Katwa",
    "Kenda",
    "Keshabpur",
    "Kharagpur",
    "Kharar",
    "Kharba",
    "Khardaha",
    "Khatra",
    "Kirnahar",
    "Kolkata",
    "Konnagar",
    "Krishnanagar",
    "Krishnapur",
    "Kshirpai",
    "Kulpi",
    "Kultali",
    "Kulti",
    "Kurseong",
    "Lalgarh",
    "Lalgola",
    "Loyabad",
    "Madanpur",
    "Madhyamgram",
    "Mahiari",
    "Mahishadal",
    "Mainaguri",
    "Manikpara",
    "Masila",
    "Mathabhanga",
    "Matiali",
    "Matigara",
    "Medinipur",
    "Mejia",
    "Memari",
    "Mirik",
    "Mohanpur",
    "Monoharpur",
    "Muragacha",
    "Muri",
    "Murshidabad",
    "Nabadwip",
    "Nabagram",
    "Nadia district",
    "Nagarukhra",
    "Nagrakata",
    "Naihati",
    "Naksalbari",
    "Nalhati",
    "Nalpur",
    "Namkhana",
    "Nandigram",
    "Nangi",
    "Nayagram",
    "North 24 Parganas district",
    "Odlabari",
    "Paikpara",
    "Panagarh",
    "Panchla",
    "Panchmura",
    "Pandua",
    "Panihati",
    "Panskura",
    "Parbatipur",
    "Paschim Medinipur district",
    "Patiram",
    "Patrasaer",
    "Patuli",
    "Pujali",
    "Puncha",
    "Purba Medinipur district",
    "Purulia",
    "Raghudebbati",
    "Raghunathpur",
    "Raiganj",
    "Rajmahal",
    "Rajnagar",
    "Ramchandrapur",
    "Ramjibanpur",
    "Ramnagar",
    "Rampur Hat",
    "Ranaghat",
    "Raniganj",
    "Raypur",
    "Rishra",
    "Sahapur",
    "Sainthia",
    "Salanpur",
    "Sankarpur",
    "Sankrail",
    "Santipur",
    "Santoshpur",
    "Santuri",
    "Sarenga",
    "Serampore",
    "Serpur",
    "Shyamnagar, West Bengal",
    "Siliguri",
    "Singur",
    "Sodpur",
    "Solap",
    "Sonada",
    "Sonamukhi",
    "Sonarpur",
    "South 24 Parganas district",
    "Srikhanda",
    "Srirampur",
    "Suri",
    "Swarupnagar",
    "Takdah",
    "Taki",
    "Tamluk",
    "Tarakeswar",
    "Titagarh",
    "Tufanganj",
    "Tulin",
    "Uchalan",
    "Ula",
    "Uluberia",
    "Uttar Dinajpur district",
    "Uttarpara Kotrung"
]

export const dynamicData = {
    //"weace": {
    //"form_id": "239",
    //"institute_id": "379",
    //"document_title": "Stride Ahead",
    //"document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with. Unlock your potential today.",
    //"is_client_route": true,
    //"is_left_sideBar_visible": true,
    //"is_mobile_verification": true,
    //"is_email_verification": false,
    //"is_Loading_Screen": false,
    //"show_test_timer": false,
    //"perQuestionTimer": false,
    //"test_time_in_seconds": "",
    //"coupon_code_verification": false,
    //"is_pagination": false,
    //"test_verification_mobile": true,
    //"is_question_navigation": true,
    //"is_working_professional_form": false,
    //"is_student_form": false,
    //"is_parent_form": false,
    //"is_college_form": false,
    //"is_Loading_Screen": false,
    //"is_school_registration_form": true,
    //"is_student_registration_form": true,
    //"proceed_to_test": false,
    //"verify_with_email": false,
    //"school_registration_route": false,
    //"student_registration_route": true,
    //"test_time_in_seconds": "5400",
    //"class_6_test_route": "167",
    //"class_7_test_route": "168",
    //"class_8_test_route": "169",
    //"class_9_test_route": "170",
    //"class_10_test_route": "171",
    //"class_11PCM_test_route": "165",
    //"class_11PCB_test_route": "164",
    //"view_report": true,
    //"default_left_section": {
    //  "heading": "Data Science Fitment Assessment",
    //  "sub_heading": "Complete the quiz to unlock expert insights!",
    //  "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //  "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
    //  "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //},
    //"loading_page": {
    //  "heading": "Our AI is calculating your results...",
    //  "pointers": [
    //    "Gathering data for personality insights...",
    //    "Creating a fitment report just for you...",
    //    "Mapping your goals with our extensive database...",
    //    "Assessing your interests and aptitudes...",
    //    "Calculating the personalized next steps based on your responses...",
    //    "Finding the right recommmendaions for you—get ready!",
    //  ]
    //},
    //"main_test_content": {
    //  "thank_you_page": {
    //    "heading": "Congratulations 🎉",
    //    "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
    //    "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
    //    "sub_heading_three": "You can give the test by clicking on the button below.",
    //  }
    //},
    //},
    "default": {
        "default_left_section": {
            "heading": "Stride Questionare",
            "sub_heading": "Complete the quiz to unlock expert insights!",
            "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
            "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
            "additional_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
        },
        "loading_page": {
            "left_section": {
                "heading": "Stride Questionare",
                "sub_heading": "Complete the quiz to unlock expert insights!",
                "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
                "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
                "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
            },
            "heading": "Our AI is calculating your results...",
            "pointers": [
                "Gathering data for personality insights...",
                "Creating a fitment report just for you...",
                "Mapping your goals with our extensive database...",
                "Assessing your interests and aptitudes...",
                "Calculating the personalized next steps based on your responses...",
                "Finding the right recommmendaions for you—get ready!",
            ]
        },
        "sign_up_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Registration Done",
                "sub_heading_two": "Thankyou for registering.",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
        "main_test_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "You will soon recieve your report by admin.",
                "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
                //"sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
        "instructions_left_section": {
            "heading": "Brainwave Career Fit Assessment",
            "sub_heading": "Before you begin, read all the instructions for each section carefully to ensure accurate completion of your test.",
            "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
            "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
            "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahad"
        },
    },
    "57": {
        "default_left_section": {
            "heading": "Let's Unbound Psychometric Assessment",
            "sub_heading": "Complete the quiz to unlock expert insights!",
            "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
            "logo_link": "",
            "additional_text": "© 2024 Lets Unbound"
        },
        "terms_and_conditions": "https://www.letsunbound.com/tnc",
        "loading_page": {
            "heading": "Calculating your results...",
            "pointers": [
                "Creating a report just for you...",
                "Mapping your goals...",
                "Assessing your interests and traits...",
                "Finding the right recommmendaions for you—get ready!",
            ]
        },
        "sign_up_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Registration Done",
                "sub_heading_two": "Thankyou for registering.",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
        "main_test_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Please contact your admin for your report.",
                "sub_heading_two": "Prepare to discover expert insights that will help you navigate your journey ahead!",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
    },
    "46": {
        "default_left_section": {
            "heading": "Your Upskilling Return on Investment",
            "sub_heading": "Each question will help you understand the impact upskilling can have on your earnings and career.",
            "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
            "logo_link": "https://stride-survey-assets.s3.amazonaws.com/abc/logo.png",
            "additional_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
        },
        "terms_and_conditions": "https://jaroeducation.com/terms-of-use/",
        "loading_page": {
            "heading": "Our algorithm is calculating your results...",
            "pointers": [
                "Gathering data for personalized insights...",
                "Mapping your goals with our extensive database...",
                "Mapping personalized next steps based on your responses...",
                "Finding the right resources for your goals...",
                "Creating a report just for you - get ready!",
            ]
        },
        "sign_up_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Registration Done",
                "sub_heading_two": "Thankyou for registering.",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
        "main_test_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
                "sub_heading_two": "Ready to take the leap?",
                "sub_heading_three": "Discover expert insights that will help you navigate your journey ahead!",
            }
        },
    },
    "48": {
        "default_left_section": {
            "heading": "GC Career Fit Assessment",
            "sub_heading": "Complete the test to unlock expert insights!",
            "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
            "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
            "additional_text": "© 2024 Global Careers || Powered by Stride Ahead"
        },
        "terms_and_conditions": "https://strideaheadspace.notion.site/TERMS-OF-SERVICE-28aa574eb00b49749875819ad8408e77",
        "loading_page": {
            "left_section": {
                "heading": "GC Career Fit Assessment",
                "sub_heading": "Complete the test to unlock expert insights!",
                "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
                "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
                "copyright_text": "© 2024 Global Careers || Powered by Stride Ahead"
            },
            "heading": "Our AI is calculating your results...",
            "pointers": [
                "Gathering data for personality insights...",
                "Creating a fitment report just for you...",
                "Mapping your goals with our extensive database...",
                "Assessing your interests and aptitudes...",
                "Calculating the personalized next steps based on your responses...",
                "Finding the right recommmendaions for you—get ready!",
            ]
        },
        "sign_up_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Registration Done",
                "sub_heading_two": "Thankyou for registering.",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
        "main_test_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Report will be shortly shared by your counsellor.",
                "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
                //"sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
        "instructions_left_section": {
            "heading": "Brainwave Career Fit Assessment",
            "sub_heading": "Before you begin, read all the instructions for each section carefully to ensure accurate completion of your test.",
            "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
            "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
            "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahad"
        },
    },
    "42": {
        "default_left_section": {
            "heading": "Future Readiness Quiz",
            "sub_heading": "Buidling Bright Futures",
            "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
            "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
            "additional_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
        },
        "terms_and_conditions": "https://jaroeducation.com/terms-of-use/",
        "loading_page": {
            "heading": "Calculating your results...",
            "pointers": [
                "Creating a report just for you...",
                "Mapping your goals...",
                "Assessing your interests and traits...",
                "Finding the right recommmendaions for you—get ready!",
            ]
        },
        "sign_up_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Registration Done",
                "sub_heading_two": "Thankyou for registering.",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
        "main_test_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
                "sub_heading_two": "Prepare to discover expert insights that will help you navigate your journey ahead!",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
    },
    "51": {
        "default_left_section": {
            "heading": "JC Stream and Career Assessment Test",
            "sub_heading": "Complete the quiz to unlock expert insights!",
            "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
            "logo_link": "https://stride-survey-assets.s3.amazonaws.com/abc/jitin_chawla.png",
            "additional_text": "© 2024 Jitin Chawla || Powered by Stride Ahead"
        },
        "terms_and_conditions": "https://www.jitinchawla.com/termsandconditions",
        "loading_page": {
            "heading": "Calculating your results...",
            "pointers": [
                "Creating a report just for you...",
                "Mapping your goals...",
                "Assessing your interests and traits...",
                "Finding the right recommmendaions for you—get ready!",
            ]
        },
        "sign_up_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Registration Done",
                "sub_heading_two": "Thankyou for registering.",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
        "main_test_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
                "sub_heading_two": "Prepare to discover expert insights that will help you navigate your journey ahead!",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
    },
    "71": {
        "default_left_section": {
            "heading": "QStudy World Stream and Career Assessment Test",
            "sub_heading": "Complete the quiz to unlock expert insights!",
            "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
            "logo_link": "https://stride-survey-assets.s3.amazonaws.com/abc/jitin_chawla.png",
            "additional_text": "© 2025 QStudy World || Powered by Stride Ahead"
        },
        "terms_and_conditions": "https://www.termsfeed.com/live/c12f4f37-0f11-47c4-9b98-7d093c3e5013",
        "loading_page": {
            "heading": "Calculating your results...",
            "pointers": [
                "Creating a report just for you...",
                "Mapping your goals...",
                "Assessing your interests and traits...",
                "Finding the right recommmendaions for you—get ready!",
            ]
        },
        "sign_up_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Registration Done",
                "sub_heading_two": "Thankyou for registering.",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
        "main_test_content": {
            "thank_you_page": {
                "heading": "Congratulations 🎉",
                "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
                "sub_heading_two": "Prepare to discover expert insights that will help you navigate your journey ahead!",
                "sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
    },
    "53": {
        "terms_and_conditions": "https://www.buildwithivb.com/terms-and-conditions",
        "main_test_content": {
            "thank_you_page": {
                "heading": "Assessment Completed!",
                "sub_heading_one": "Thank you for completing the assessment.",
                "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
                //"sub_heading_three": "You can give the test by clicking on the button below.",
            }
        },
    },
    "29": {
        "terms_and_conditions": "https://strideaheadspace.notion.site/TERMS-OF-SERVICE-28aa574eb00b49749875819ad8408e77",
    },
    //"Unitycollege": {
    //"form_id": "239",
    //"institute_id": "380",
    //"document_title": "Stride Ahead",
    //"document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with. Unlock your potential today.",
    //"is_client_route": true,
    //"is_left_sideBar_visible": true,
    //"is_mobile_verification": true,
    //"is_email_verification": false,
    //"is_Loading_Screen": false,
    //"show_test_timer": false,
    //"perQuestionTimer": false,
    //"test_time_in_seconds": "",
    //"coupon_code_verification": false,
    //"is_pagination": false,
    //"test_verification_mobile": true,
    //"is_question_navigation": true,
    //"is_working_professional_form": false,
    //"is_student_form": false,
    //"is_parent_form": false,
    //"is_college_form": false,
    //"is_Loading_Screen": false,
    //"is_school_registration_form": true,
    //"is_student_registration_form": true,
    //"proceed_to_test": false,
    //"verify_with_email": false,
    //"school_registration_route": false,
    //"student_registration_route": true,
    //"test_time_in_seconds": "5400",
    //"class_6_test_route": "167",
    //"class_7_test_route": "168",
    //"class_8_test_route": "169",
    //"class_9_test_route": "170",
    //"class_10_test_route": "171",
    //"class_11PCM_test_route": "165",
    //"class_11PCB_test_route": "164",
    //"view_report": true,
    //  "default_left_section": {
    //    "heading": "Data Science Fitment Assessment",
    //    "sub_heading": "Complete the quiz to unlock expert insights!",
    //    "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //    "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
    //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //  },
    //  "loading_page": {
    //    "left_section": {
    //      "heading": "Data Science Fitment Assessment",
    //      "sub_heading": "Complete the quiz to unlock expert insights!",
    //      "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //      "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
    //      "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //    },
    //    "heading": "Our AI is calculating your results...",
    //    "pointers": [
    //      "Gathering data for personality insights...",
    //      "Creating a fitment report just for you...",
    //      "Mapping your goals with our extensive database...",
    //      "Assessing your interests and aptitudes...",
    //      "Calculating the personalized next steps based on your responses...",
    //      "Finding the right recommmendaions for you—get ready!",
    //    ]
    //  },
    //  "sign_up_content": {
    //    "thank_you_page": {
    //      "heading": "Congratulations 🎉",
    //      "sub_heading_one": "Registration Done",
    //      "sub_heading_two": "Thankyou for registering.",
    //      "sub_heading_three": "You can give the test by clicking on the button below.",
    //    }
    //  },
    //  "main_test_content": {
    //    "thank_you_page": {
    //      "heading": "Congratulations 🎉",
    //      "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
    //      "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
    //      //"sub_heading_three": "You can give the test by clicking on the button below.",
    //    }
    //  },
    //  "instructions_left_section": {
    //    "heading": "Brainwave Career Fit Assessment",
    //    "sub_heading": "Before you begin, read all the instructions for each section carefully to ensure accurate completion of your test.",
    //    "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //    "logo_link": "https://i.ibb.co/FWMCFMd/unity-logo-Transparent.png",
    //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahad"
    //  },
    //},
    //"internshala": {
    //"form_id": "229",
    //"institute_id": "367",
    //"document_title": "Stride Ahead",
    //"document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with. Unlock your potential today.",
    //"is_client_route": true,
    //"is_left_sideBar_visible": true,
    //"is_mobile_verification": true,
    //"is_email_verification": false,
    //"is_Loading_Screen": false,
    //"show_test_timer": false,
    //"perQuestionTimer": false,
    //"test_time_in_seconds": "",
    //"coupon_code_verification": false,
    //"is_pagination": false,
    //"test_verification_mobile": true,
    //"is_question_navigation": true,
    //"is_working_professional_form": false,
    //"is_student_form": false,
    //"is_parent_form": false,
    //"is_college_form": false,
    //"is_Loading_Screen": false,
    //"is_school_registration_form": true,
    //"is_student_registration_form": true,
    //"proceed_to_test": false,
    //"verify_with_email": false,
    //"school_registration_route": false,
    //"student_registration_route": true,
    //"test_time_in_seconds": "5400",
    //"class_6_test_route": "167",
    //"class_7_test_route": "168",
    //"class_8_test_route": "169",
    //"class_9_test_route": "170",
    //"class_10_test_route": "171",
    //"class_11PCM_test_route": "165",
    //"class_11PCB_test_route": "164",
    //"view_report": true,
    //"default_left_section": {
    //  "heading": "Data Science Fitment Assessment",
    //  "sub_heading": "Complete the quiz to unlock expert insights!",
    //  "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //  "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
    //  "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //},
    //"loading_page": {
    //  "left_section": {
    //    "heading": "Data Science Fitment Assessment",
    //    "sub_heading": "Complete the quiz to unlock expert insights!",
    //    "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //    "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
    //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //  },
    //  "heading": "Our AI is calculating your results...",
    //  "pointers": [
    //    "Gathering data for personality insights...",
    //    "Creating a fitment report just for you...",
    //    "Mapping your goals with our extensive database...",
    //    "Assessing your interests and aptitudes...",
    //    "Calculating the personalized next steps based on your responses...",
    //    "Finding the right recommmendaions for you—get ready!",
    //  ]
    //},
    //"sign_up_content": {
    //  "thank_you_page": {
    //    "heading": "Congratulations 🎉",
    //    "sub_heading_one": "Registration Done",
    //    "sub_heading_two": "Thankyou for registering.",
    //    "sub_heading_three": "You can give the test by clicking on the button below.",
    //  }
    //},
    //"main_test_content": {
    //  "thank_you_page": {
    //    "heading": "Congratulations 🎉",
    //    "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
    //    "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
    //    "sub_heading_three": "You can give the test by clicking on the button below.",
    //  }
    //},
    //"instructions_left_section": {
    //  "heading": "Brainwave Career Fit Assessment",
    //  "sub_heading": "Before you begin, read all the instructions for each section carefully to ensure accurate completion of your test.",
    //  "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //  "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
    //  "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahad"
    //},
    //},
    //"jc": {
    //  "form_id": "191",
    //  "institute_id": "358",
    //  "document_title": "jc",
    //  "document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with - askIITians-iCAT. Unlock your potential today.",
    //  "is_client_route": true,
    //  "is_left_sideBar_visible": true,
    //  "is_mobile_verification": false,
    //  "is_email_verification": true,
    //  "is_Loading_Screen": false,
    //  "show_test_timer": false,
    //  "perQuestionTimer": false,
    //  "test_time_in_seconds": "",
    //  "coupon_code_verification": true,
    //  "is_pagination": false,
    //  "test_verification_mobile": true,
    //  "is_question_navigation": true,
    //  "is_working_professional_form": false,
    //  "is_student_form": false,
    //  "is_parent_form": false,
    //  "is_college_form": false,
    //  "is_Loading_Screen": false,
    //  "is_school_registration_form": true,
    //  "is_student_registration_form": true,
    //  "proceed_to_test": true,
    //  "verify_with_email": false,
    //  "school_registration_route": "105",
    //  "student_registration_route": "174",
    //  "test_time_in_seconds": "5400",
    //  "class_6_test_route": "167",
    //  "class_7_test_route": "168",
    //  "class_8_test_route": "169",
    //  "class_9_test_route": "170",
    //  "class_10_test_route": "171",
    //  "class_11PCM_test_route": "165",
    //  "class_11PCB_test_route": "164",
    //  "view_report": false,
    //  "default_left_section": {
    //    "heading": "Brainwave Career Fit Assessment",
    //    "sub_heading": "Sign up to unlock personalized career recommendations and expert guidance!",
    //    "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //    "logo_link": "https://i.ibb.co/pxt0cQ4/jitinchawlalogo.png",
    //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //  },
    //  "loading_page": {
    //    "left_section": {
    //      "heading": "Brainwave Career Fit Assessment",
    //      "sub_heading": "Your test is completed!",
    //      "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //      "logo_link": "https://i.ibb.co/pxt0cQ4/jitinchawlalogo.png",
    //      "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //    },
    //    "heading": "Our AI is calculating your results...",
    //    "pointers": [
    //      "Gathering data for personality insights...",
    //      "Mapping your interests with our extensive database...",
    //      "Assessing your aptitudes...",
    //      "Calculating the best career paths based on your responses...",
    //      "Creating a career report just for you...",
    //      "Finding the right next steps for you—get ready!",
    //    ]
    //  },
    //  "sign_up_content": {
    //    "thank_you_page": {
    //      "heading": "Congratulations 🎉",
    //      "sub_heading_one": "Registration Done",
    //      "sub_heading_two": "Thankyou for registering.",
    //      "sub_heading_three": "You can give the test by clicking on the button below.",
    //    }
    //  },
    //  "main_test_content": {
    //    "thank_you_page": {
    //      "heading": "Congratulations 🎉",
    //      "sub_heading_one": "Are you eager to explore your results and gain valuable insights?",
    //      "sub_heading_two": "Ready to take the leap? Discover expert insights that will help you navigate your journey ahead!",
    //      //"sub_heading_three": "You can give the test by clicking on the button below.",
    //    }
    //  },
    //  "instructions_left_section": {
    //    "heading": "Brainwave Career Fit Assessment",
    //    "sub_heading": "Before you begin, read all the instructions for each section carefully to ensure accurate completion of your test.",
    //    "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //    "logo_link": "https://i.ibb.co/pxt0cQ4/jitinchawlalogo.png",
    //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //  },
    //},
    //"deafult": {
    //  "document_title": "Stride Survey",
    //  "document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with. Unlock your potential today.",
    //  "left_section": {
    //    "heading": "Discover What's Best For You!",
    //    "sub_heading": "Know your skill gaps and discuss potential career paths with industry experts. Grab the chance to get that career boost you were always hoping for."
    //  },
    //},
    //"ttm": {
    //  "document_title": "Talk To Mentor",
    //  "document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with - Talk To Mentor. Unlock your potential today.",
    //"is_client_route": true,
    //"college_route": "223",
    //"working_professional_route": "225",
    //"is_student_form": false,
    //"is_parent_form": false,
    //"is_college_form": true,
    //"is_left_sideBar_visible": true,
    //"test_verification_mobile": true,
    //"coupon_code_verification": false,
    //"view_report": false,
    //"is_working_professional_form": true,
    //"is_mobile_verification": true,
    //"is_email_verification": false,
    //"is_Loading_Screen": true,
    //"show_test_timer": false,
    //"perQuestionTimer": false,
    //"test_time_in_seconds": "",
    //"is_pagination": false,
    //"is_question_navigation": true,
    //"proceed_to_test": false,
    //"verify_with_email": false,
    //  "school_registration_route": false,
    //  "student_registration_route": true,
    //  "default_left_section": {
    //    "heading": "Discover What's Best For You!",
    //    "sub_heading": "Know your skill gaps and discuss potential career paths with industry experts. Grab the chance to get that career boost you were always hoping for",
    //    "image_link": "https://stride-survey-assets.s3.amazonaws.com/stride/mainbannerlink",
    //    "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
    //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //  },
    //  "loading_page": {
    //    "heading": "Calculating your results.",
    //    "pointers": [
    //      "Initializing our AI Model.",
    //      "Accessing strengths and areas to improve.",
    //      "Finding a mentor to guide you.",
    //      "Analyzing best career path options.",
    //    ]
    //  },
    //  "thank_you_page": {
    //  }
    //},
    //"strideSurvey": {
    //  "document_title": "Tutoring Surveys",
    //  "document_description": "Our assessment platform empowers future leaders with personalized guidance. Discover your strengths and areas for growth with Tutoring Surveys. Unlock your potential today.",
    //  "is_client_route": true,
    //  "is_student_form": true,
    //  "is_parent_form": true,
    //  "is_college_form": false,
    //  "is_left_sideBar_visible": true,
    //  "is_working_professional_form": false,
    //  "is_mobile_verification": true,
    //  "is_email_verification": false,
    //  "is_Loading_Screen": true,
    //  "test_verification_mobile": true,
    //  "student_route": "65",
    //  "parent_route": "66",
    //  "view_report": false,
    //  "coupon_code_verification": false,
    //  "show_test_timer": false,
    //  "perQuestionTimer": false,
    //  "test_time_in_seconds": "",
    //  "is_pagination": false,
    //  "is_question_navigation": false,
    //  "proceed_to_test": false,
    //  "verify_with_email": false,
    //  "default_left_section": {
    //    "heading": "Stay Ahead of the Curve",
    //    "sub_heading": "Industries are changing rapidly. A well-planned career path can give your child the edge they need to stand out in a competitive job market. Complete the quiz and help your child stay ahead of the curve.",
    //    "image_link": "https://i.ibb.co/RzKhKKR/Info-Graphic.png",
    //    "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
    //    "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //  },
    //  "student_content": {
    //    "left_section": {
    //      "heading": "Study Quotient",
    //      "sub_heading": "Discover your Study Quotient in just minutes! Uncover your strengths and areas for growth across 8 key academic skills. Finish this quiz to level-up your study game!",
    //      "image_link": "https://i.ibb.co/RzKhKKR/Info-Graphic.png",
    //      "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
    //      "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //    },
    //    "loading_page": {
    //      "heading": "Our AI is thinking about your answers...",
    //      "pointers": [
    //        "Gathering data for personalized insights...",
    //        "Making a special career report just for you...",
    //        "Mapping your goals and needs with our extensive database...",
    //        "Calculating the best career paths based on your responses...",
    //        "Finding the right next steps for you—get ready!",
    //      ]
    //    },
    //    "thank_you_page": {
    //      "heading": "Congratulations on completing the Quiz!",
    //      "sub_heading_one": "You Rock for Taking the First Step!",
    //      "sub_heading_two": "Thanks for completing the assessment. You're on your way to unlocking a world of opportunities tailored just for you.",
    //      "multiple_sections": [
    //        {
    //          "heading": "Curious About Your Results?",
    //          "sub_heading": "Dive deep into your learning styles, strengths, and areas for improvement.",
    //          "cta_text": "Download Your Comprehensive PDF Report Here",
    //          "cta_links": "https://www.africau.edu/images/default/sample.pdf",
    //        },
    //        {
    //          "heading": "Ready to Level Up?",
    //          "sub_heading": "Get personalized guidance to help you navigate academic challenges and plan your career",
    //          "cta_text": "Book a One-on-One Mentorship Session",
    //          "cta_links": "https://booking.strideahead.in/",
    //        },
    //        {
    //          "heading": "Share the Wisdom: Get Your Parents Involved!",
    //          "sub_heading": "If they've already taken it, great! More customized mentorship is on the way for you.",
    //          "cta_text": "Tell Your Parent to Take the Assessment",
    //          "cta_links": "/nniit/66",
    //        },
    //        {
    //          "heading": "Exclusive Perks Just for You!",
    //          "sub_heading": "This is your gateway to exclusive content, offers, and much more.",
    //          "cta_text": "Check Out Our Special Offers",
    //          "cta_links": "https://strideahead.io/",
    //        },
    //      ],
    //    }
    //  },
    //  "parent_content": {
    //    "left_section": {
    //      "heading": "Stay Ahead of the Curve",
    //      "sub_heading": "Industries are changing rapidly. A well-planned career path can give your child the edge they need to stand out in a competitive job market. Complete the quiz and help your child stay ahead of the curve.",
    //      "image_link": "https://i.ibb.co/RzKhKKR/Info-Graphic.png",
    //      "logo_link": "https://i.ibb.co/kXn3Y9c/logo.png",
    //      "copyright_text": "© 2024 Stride Ahead || Powered by Stride Ahead"
    //    },
    //    "loading_page": {
    //      "heading": "Our AI is thinking about your answers...",
    //      "pointers": [
    //        "Analyzing your responses for personalized insights...",
    //        "Calculating data-driven guidance for your child's career...",
    //        "Crafting a roadmap based on your input...",
    //        "Finalizing tailored next steps—almost there!",
    //      ]
    //    },
    //    "thank_you_page": {
    //      "heading": "Thank You for Your Insight!",
    //      "sub_heading_one": "Your invaluable responses help us customize discussions and mentorship opportunities to align seamlessly with your child's career goals.",
    //      "multiple_sections": [
    //        {
    //          "heading": "Next Step: Help Your Child Unlock Their Full Potential",
    //          "sub_heading": "A comprehensive report will be generated that will immensely benefit your child in planning their future roadmap.",
    //          "cta_text": "Ask Your Child to Take the Study Quotient Assessment",
    //          "cta_links": "/nniit/65",
    //        },
    //        {
    //          "heading": "Already Taken the Assessment?",
    //          "sub_heading": "Our counseling team will reach out shortly to arrange a personalized session. Alternatively, feel free to book a slot in the calendar below.",
    //          "cta_text": "Book a Mentorship Session",
    //          "cta_links": "https://booking.strideahead.in/",
    //        },
    //        {
    //          "heading": "Special Offers Just for You ",
    //          "sub_heading": "",
    //          "cta_text": "Unlock Exclusive Content",
    //          "cta_links": "https://strideahead.io/",
    //        },
    //      ],
    //    }
    //  }
    //}
}

const imageData = [
    {
        id: 'option1',
        label: 'Image 1',
        imageUrl: 'https://c8.alamy.com/comp/2C86TRW/portrait-of-a-healthy-pleasant-looking-senior-woman-with-grey-hair-and-kind-face-looking-at-camera-while-posing-indoors-2C86TRW.jpg'
    },
    {
        id: 'option2',
        label: 'Image 2',
        imageUrl: 'https://c8.alamy.com/comp/2C86TRW/portrait-of-a-healthy-pleasant-looking-senior-woman-with-grey-hair-and-kind-face-looking-at-camera-while-posing-indoors-2C86TRW.jpg'
    },
    {
        id: 'option3',
        label: 'Image 3',
        imageUrl: 'https://c8.alamy.com/comp/2C86TRW/portrait-of-a-healthy-pleasant-looking-senior-woman-with-grey-hair-and-kind-face-looking-at-camera-while-posing-indoors-2C86TRW.jpg'
    }
];

export const imageQuestion = {
    text: "Select an image:",
    options: imageData
};

export const schoolData = {
    "jc": {
        "primary": {
            "test_Information": {
                "heading_one": <>• <b>Test Grade:</b></>,
                "heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
                "heading_three": <>• <b>Number of Questions:</b> 105</>,
                "heading_four": <>• <b>Question Type:</b> MCQ</>,
            },
            "general_instructions": {
                "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
                "heading_one": "• This paper contains 105 questions divided into 2 segments. ",
                "segment_1": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest.</>,
                "segment_2": <>• <b>Segment -2 [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
                "heading_two": <>• <b>Questions marked in red [segment 1] are compulsory to answer. You won't be able to submit your assessment before answering all of these.</b></>,
                "heading_three": "• There is no internal choice in questions.",
                "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. Refrain from using any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device.",
                "heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
            },
            "segment_one_instructions": {
                "heading_one": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest </>,
                "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
                "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment.</b></>,
                "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
                "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
                "heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
            },
            "segment_two_instructions": {
                "heading_one": <>• This segment<b> [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
                "heading_two": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
                "heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
                "heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
                "heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
            },
            "marking_scheme": {
                "section_a": {
                    "heading": "A",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_b": {
                    "heading": "B",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_c": {
                    "heading": "B",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
            }
        },
        "junior": {
            "test_Information": {
                "heading_one": <>• <b>Test Grade:</b></>,
                //"heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
                "heading_three": <>• <b>Number of Questions:</b> 105</>,
                "heading_four": <>• <b>Question Type:</b> MCQ</>,
            },
            "general_instructions": {
                "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
                "heading_one": "• This paper contains 105 questions divided into 2 segments. ",
                "segment_1": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest.</>,
                "segment_2": <>• <b>Segment -2 [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
                "heading_two": <>• <b>Questions marked in red [segment 1] are compulsory to answer. You won't be able to submit your assessment before answering all of these.</b></>,
                "heading_three": "• There is no internal choice in questions.",
                "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. Refrain from using any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device.",
                //"heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
            },
            "segment_one_instructions": {
                "heading_one": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest </>,
                "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
                "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment.</b></>,
                "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
                "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
                //"heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
            },
            "segment_two_instructions": {
                "heading_one": <>• This segment<b> [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
                "heading_two": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
                //"heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
                //"heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
                //"heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
            },
            "marking_scheme": {
                "section_a": {
                    //"heading" : "A",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                //"section_b": {
                //  "correct_attempt": "4",
                //  "incorrect_attempt": "-1",
                //  "unattempted": "0",
                //},
                //"section_c": {
                //  "correct_attempt": "4",
                //  "incorrect_attempt": "-1",
                //  "unattempted": "0",
                //},
            }
        },
        "senior_pcm": {
            "test_Information": {
                "heading_one": <>• <b>Test Grade:</b></>,
                //"heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
                "heading_three": <>• <b>Number of Questions:</b> 105</>,
                "heading_four": <>• <b>Question Type:</b> MCQ</>,
            },
            "general_instructions": {
                "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
                "heading_one": "• This paper contains 105 questions divided into 2 segments. ",
                "segment_1": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest.</>,
                "segment_2": <>• <b>Segment -2 [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
                "heading_two": <>• <b>Questions marked in red [segment 1] are compulsory to answer. You won't be able to submit your assessment before answering all of these.</b></>,
                "heading_three": "• There is no internal choice in questions.",
                "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. Refrain from using any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device.",
                //"heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
            },
            "segment_one_instructions": {
                "heading_one": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest </>,
                "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
                "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment.</b></>,
                "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
                "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
                //"heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
            },
            "segment_two_instructions": {
                "heading_one": <>• This segment<b> [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
                "heading_two": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
                //"heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
                //"heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
                //"heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
            },
            "marking_scheme": {
                "section_a": {
                    //"heading" : "A",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                //"section_b": {
                //  "correct_attempt": "4",
                //  "incorrect_attempt": "-1",
                //  "unattempted": "0",
                //},
                //"section_c": {
                //  "correct_attempt": "4",
                //  "incorrect_attempt": "-1",
                //  "unattempted": "0",
                //},
            }
        },
        "senior_pcb": {
            "test_Information": {
                "heading_one": <>• <b>Test Grade:</b></>,
                //"heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
                "heading_three": <>• <b>Number of Questions:</b> 105</>,
                "heading_four": <>• <b>Question Type:</b> MCQ</>,
            },
            "general_instructions": {
                "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
                "heading_one": "• This paper contains 105 questions divided into 2 segments. ",
                "segment_1": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest.</>,
                "segment_2": <>• <b>Segment -2 [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
                "heading_two": <>• <b>Questions marked in red [segment 1] are compulsory to answer. You won't be able to submit your assessment before answering all of these.</b></>,
                "heading_three": "• There is no internal choice in questions.",
                "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. Refrain from using any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device.",
                //"heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
            },
            "segment_one_instructions": {
                "heading_one": <>• <b>Segment -1 [Ques 1-65]</b> contains 65 questions across personality and Interest </>,
                "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
                "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment.</b></>,
                "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
                "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
                //"heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
            },
            "segment_two_instructions": {
                "heading_one": <>• This segment<b> [Ques 65-105]</b> contains 40 questions across 5 aptitudes: Numerical Ability, Logical Reasoning, Data Interpretation, Verbal Ability, and Mechanical Ability.</>,
                "heading_two": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
                //"heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
                //"heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
                //"heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
            },
            "marking_scheme": {
                "section_a": {
                    //"heading" : "A",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                //"section_b": {
                //  "correct_attempt": "4",
                //  "incorrect_attempt": "-1",
                //  "unattempted": "0",
                //},
                //"section_c": {
                //  "correct_attempt": "4",
                //  "incorrect_attempt": "-1",
                //  "unattempted": "0",
                //},
            }
        }
    },
    "icat": {
        "primary": {
            "test_Information": {
                "heading_one": <>• <b>Test Grade:</b></>,
                "heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
                "heading_three": <>• <b>Number of Questions:</b> 108</>,
                "heading_four": <>• <b>Question Type:</b> MCQ and Multi Select</>,
            },
            "general_instructions": {
                "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
                "heading_one": "• This paper contains 108 questions divided into 2 segments.",
                "segment_1": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest</>,
                "segment_2": <>• <b>Segment -2 [Ques 79-108]</b> contains 30 Aptitude Questions that are Subject-Knowledge based across Maths and Science, Aptitude & Logical reasoning based problems, and  Data Interpretation based problems.</>,
                "heading_two": <>• <b>All questions in segment 1 are compulsory to answer. To ensure your results are taken into account, prioritize completing this segment first.</b></>,
                "heading_three": "• There is no internal choice in questions.",
                "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. You are not allowed to use any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device. If you are found using any of the above items, it will be treated as resorting to unfair means and your current examination will be canceled. Further, you will be debarred from future examinations.",
                "heading_four": <>• Test duration is<b> 45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
            },
            "segment_one_instructions": {
                "heading_one": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest</>,
                "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
                "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment first.</b></>,
                "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
                "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
                "heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
            },
            "segment_two_instructions": {
                "heading_one": <>• This segment<b> [Ques 79-108]</b> contains 30 questions divided into 3 sections. </>,
                "heading_two": <>• <b>Section-A</b> contains 20 Subject-Knowledge Based questions across Maths and Science.</>,
                "heading_three": <>• <b>Section-B</b> contains 7 Aptitude & Logical reasoning based problems.</>,
                "heading_four": <>• <b>Section-C </b> contains 3 Data Interpretation based problems.</>,
                "heading_five": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
            },
            "marking_scheme": {
                "section_a": {
                    "heading": "A",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_b": {
                    "heading": "B",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_c": {
                    "heading": "C",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
            }
        },
        "junior": {
            "test_Information": {
                "heading_one": <>• <b>Test Grade:</b></>,
                "heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
                "heading_three": <>• <b>Number of Questions:</b> 108</>,
                "heading_four": <>• <b>Question Type:</b> MCQ and Multi Select</>,
            },
            "general_instructions": {
                "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
                "heading_one": "• This paper contains 108 questions divided into 2 segments.",
                "segment_1": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
                "segment_2": <>• <b>Segment -2</b> contains 30 Aptitude Questions that are Subject-Knowledge based across Maths and Science, Aptitude & Logical reasoning based problems, and  Data Interpretation based problems.</>,
                "heading_two": <>• <b>All questions in segment 1 are compulsory to answer. To ensure your results are taken into account, prioritize completing this segment first.</b></>,
                "heading_three": "• There is no internal choice in questions.",
                "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. You are not allowed to use any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device. If you are found using any of the above items, it will be treated as resorting to unfair means and your current examination will be canceled. Further, you will be debarred from future examinations.",
                "heading_four": <>• Test duration is <b>45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
            },
            "segment_one_instructions": {
                "heading_one": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
                "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
                "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment first.</b></>,
                "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
                "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
                "heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
            },
            "segment_two_instructions": {
                "heading_one": <>• This segment<b> [Ques 79-108]</b> contains 30 questions divided into 3 sections. </>,
                "heading_two": <>• <b>Section-A</b> contains 24 Subject-Knowledge Based questions across Mathematics and Science.</>,
                "heading_three": <>• <b>Section-B</b> contains 6 Aptitude & Logical Reasoning based problems.</>,
                "heading_four": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
            },
            "marking_scheme": {
                "section_a": {
                    "heading": "A",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_b": {
                    "heading": "B",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_c": {
                    "heading": "C",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
            }
        },
        "senior_pcm": {
            "test_Information": {
                "heading_one": <>• <b>Test Grade:</b></>,
                "heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
                "heading_three": <>• <b>Number of Questions:</b> 103</>,
                "heading_four": <>• <b>Question Type:</b> MCQ and Multi Select</>,
            },
            "general_instructions": {
                "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
                "heading_one": "• This paper contains 103 questions divided into 2 segments.",
                "segment_1": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
                "segment_2": <>• <b>Segment -2 [Ques 79-103]</b> contains 25 Aptitude Questions that are Subject-Knowledge based across Physics, Chemistry, Mathematics, Aptitude & Logical Reasoning based problems.</>,
                "heading_two": <>• <b>All questions in segment 1 are compulsory to answer. To ensure your results are taken into account, prioritize completing this segment first.</b></>,
                "heading_three": "• There is no internal choice in questions.",
                "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. You are not allowed to use any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device. If you are found using any of the above items, it will be treated as resorting to unfair means and your current examination will be canceled. Further, you will be debarred from future examinations.",
                "heading_four": <>• Test duration is <b>45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,

            },
            "segment_one_instructions": {
                "heading_one": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
                "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
                "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment first.</b></>,
                "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
                "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
                "heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
            },
            "segment_two_instructions": {
                "heading_one": <>• This segment<b> [Ques 79-103]</b> contains 25 questions divided into 3 sections. </>,
                "heading_two": <>• <b>Section-A</b> contains 21 Subject-Knowledge Based questions across Physics, Chemistry and Mathematics.</>,
                "heading_three": <>• <b>Section-B</b> contains 4 Aptitude & Logical Reasoning based problems.</>,
                "heading_four": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
            },
            "marking_scheme": {
                "section_a": {
                    "heading": "A",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_b": {
                    "heading": "B",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_c": {
                    "heading": "C",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
            }
        },
        "senior_pcb": {
            "test_Information": {
                "heading_one": <>• <b>Test Grade:</b></>,
                "heading_two": <>• <b>Test Duration:</b> 45 Mins</>,
                "heading_three": <>• <b>Number of Questions:</b> 103</>,
                "heading_four": <>• <b>Question Type:</b> MCQ and Multi Select</>,
            },
            "general_instructions": {
                "bold_heading_one": <><b>To ensure your responses are recorded, please remember to click 'Submit' after answering each question; otherwise, your input will not be saved.</b></>,
                "heading_one": "• This paper contains 103 questions divided into 2 segments.",
                "segment_1": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
                "segment_2": <>• <b>Segment -2 [Ques 79-103]</b>  contains 25 Aptitude Questions that are Subject-Knowledge based across Physics, Chemistry, Biology, Aptitude & Logical Reasoning based problems.</>,
                "heading_two": <>• <b>All questions in segment 1 are compulsory to answer. To ensure your results are taken into account, prioritize completing this segment first</b></>,
                "heading_three": "• There is no internal choice in questions.",
                "paragraph_one": "• Keep necessary stationery handy, like blank sheets, pencil, erasers etc. You are not allowed to use any textual material, Calculators, Document, Slides, Rules, Log Tables, Electronic watches with facilities of calculator, printed or written materials, bits of paper, mobile phone, pager or any other device. If you are found using any of the above items, it will be treated as resorting to unfair means and your current examination will be canceled. Further, you will be debarred from future examinations.",
                "heading_four": <>• Test duration is <b>45 minutes</b>. Keep an eye on the timer on your screen to manage your time effectively during the test.</>,
            },
            "segment_one_instructions": {
                "heading_one": <>• <b>Segment -1 [Ques 1-78]</b> contains 78 Psychometric questions across personality and Interest </>,
                "heading_two": "• In the following questions, choose the option that is best suited to you. There are no right or wrong answers.",
                "heading_three": <>• <b>All the questions are compulsory to answer! To ensure your results are taken into account, prioritize completing this segment first.</b></>,
                "heading_four": "• Only spend 10 - 20 seconds on a question and move to the next.",
                "heading_five": "• Answer the questions as the way you are and not the way you’d like to be seen by others.",
                "heading_six": <>• <b>Some questions offer a chance to mark multiple options, read these questions carefully and mark as many options as stated by the question.</b></>,
            },
            "segment_two_instructions": {
                "heading_one": <>• This segment<b> [Ques 79-103]</b> contains 25 questions divided into 3 sections. </>,
                "heading_two": <>• <b>Section-A</b> contains 21 Subject-Knowledge Based questions across Physics, Chemistry and Biology. </>,
                "heading_three": <>• <b>Section-B</b> contains 4 Aptitude & Logical Reasoning based problems.</>,
                "heading_four": "• Kindly be aware that this section contains both correct and incorrect responses, with a negative marking for incorrect answers. You can choose to leave questions unanswered if you prefer.",
            },
            "marking_scheme": {
                "section_a": {
                    "heading": "A",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_b": {
                    "heading": "B",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
                "section_c": {
                    "heading": "C",
                    "correct_attempt": "4",
                    "incorrect_attempt": "-1",
                    "unattempted": "0",
                },
            }
        }
    }
}