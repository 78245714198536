import { useState, useEffect } from 'react';

const useFetchUserCountry = () => {
    const [countryCode, setCountryCode] = useState( null );
    const [loading, setLoading] = useState( true );
    const [error, setError] = useState( null );

    useEffect( () => {
        fetch( 'https://ipapi.co/json/' )
            .then( response => {
                if ( !response.ok ) {
                    throw new Error( 'Network response was not ok.' );
                }
                return response.json();
            } )
            .then( data => {
                setCountryCode( data.country );
                setLoading( false );
            } )
            .catch( err => {
                console.error( 'Error fetching geolocation data:', err );
                setError( err );
                setLoading( false );
            } );
    }, [] );

    return { countryCode, loading, error };
};

export default useFetchUserCountry;
