import React, { useState, useEffect } from "react";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import SubmitButton from "../SubmitButton/SubmitButton";
import { dynamicData } from "../../helpers/data";
import { useSelector } from 'react-redux';
import useUserResponseFunctions from "../../helpers/SaveUserResponse";
import '../../styles.css';

const DobInputType = ( { question, selectedAnswer, onNext, onBack, isPagination, isQuestionNavigation, isValidation, onClearAll, index } ) => {
    const [isAnswerFilled, setIsAnswerFilled] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const { SaveUserResponseLocalStorage } = useUserResponseFunctions();

    const [userResponse, setUserResponse] = useState( {
        selectedOptions: selectedAnswer?.selectedOptions || [],
        textResponse: selectedAnswer?.textResponse || '',
    } );

    useEffect( () => {
        setUserResponse( {
            selectedOptions: selectedAnswer?.selectedOptions || [],
            textResponse: selectedAnswer?.textResponse || '',
        } );
    }, [question] );

    useEffect( () => {
        if ( selectedAnswer?.textResponse ) {
            setIsAnswerFilled( true );
            isValidation( true );
            setUserResponse( {
                selectedOptions: selectedAnswer?.selectedOptions || [],
                textResponse: selectedAnswer?.textResponse || '',
            } );
        } else {
            setIsAnswerFilled( false );
            isValidation( false );
        }
    }, [question] );

    const handleAnswerChange = ( event ) => {
        const date = event.target.value;
        const userSelectedAnswer = {
            ...userResponse,
            textResponse: date,
        };
        setUserResponse( userSelectedAnswer );
        SaveUserResponseLocalStorage( { question: question, userResponse: userSelectedAnswer, isPagination: isPagination, index: index } );
        if ( date ) {
            setIsAnswerFilled( true );
            isValidation( true );
        } else {
            setIsAnswerFilled( false );
            isValidation( false );
        }
    };

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleClearAll = () => {
        onClearAll();
    };

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <div>
                    <input
                        type="date"
                        className="sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                        onChange={ handleAnswerChange }
                        value={ userResponse?.textResponse }
                    />
                </div>
            </div>
            {
                isPagination ? <></>
                    : isQuestionNavigation ? (
                        <>
                            <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
                        </>
                    ) : (
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            { question?.question_optional === "optional" && (
                                <button className="skip-button" onClick={ onNext }>
                                    Skip
                                </button>
                                ) }
                                { perQuestionTimer ? <></> : (
                                    <button className="back-button" onClick={ onBack }>
                                    Back
                                </button>
                                ) }
                                <button
                                    className="next-button"
                                    onClick={ !isAnswerFilled ? null : onNext }
                                    style={ {
                                        backgroundColor: !isAnswerFilled ? `${ clientConfig.primary_color }80` : `${ clientConfig.primary_color }`,
                                        cursor: !isAnswerFilled ? 'not-allowed' : 'pointer'
                                    } }
                                >
                                    Next
                                </button>
                            </div>
                        )
            }
        </div>
    );
};

export default DobInputType;
